import tw, { styled } from 'twin.macro'

export const Title = tw.h2`
  text-sm font-medium
`

const StyledH3 = styled.h3<{ large?: boolean }>(({ large }) => [large ? tw`text-base` : tw`text-sm`])

export const SubTitle = tw(StyledH3)`
  font-medium
`

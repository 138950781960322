import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { CLASSIC_PRINTS_ID } from '../consts'

import { Step, IStep } from './Step'

export const DynamicConfiguration = types
  .model({
    id: types.string,
    steps: types.array(Step),
    productConfigurationsUrl: types.string,
    userConfigurationsUrl: types.string,
    configurationFilesUrl: types.string,
  })
  .views((self) => ({
    get currentStep(): IStep | undefined {
      return self.steps.find((s) => !s.isCompleted)
    },
    get displayDiscountScale() {
      return self.id === CLASSIC_PRINTS_ID
    },
  }))
  .actions((self) => ({
    setConfigurationId(id: string) {
      self.id = id
    },
  }))

export interface IDynamicConfiguration extends Instance<typeof DynamicConfiguration> {}
export interface IDynamicConfigurationIn extends SnapshotIn<typeof DynamicConfiguration> {}
export interface IDynamicConfigurationOut extends SnapshotOut<typeof DynamicConfiguration> {}

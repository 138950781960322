import React from 'react'
import { observer } from 'mobx-react-lite'
import 'twin.macro'

import { DEBUG } from '../../consts'
import { useMst } from '../../models/RootStore'
import { PrimaryButton } from '../../components/Button'
import { Animation } from '../../components/Animation'
import ProgressBar from '../../components/ProgressBar'

import Rocket from '../../assets/animations/Rocket.json'
import UFO from '../../assets/img/UFO.svg'
import { Content } from '../../components/layout/Content'
import { Page } from '../../components/layout/Page'
import { VerticalContents } from '../../components/VerticalContents'
import { Modal } from '../../components/Modal'
import { Title } from '../../components/Title'
import { ErrorModal } from '../../components/Error'
import { useTranslation } from 'react-i18next'

export const AddToCart: React.FC = observer(() => {
  const { t } = useTranslation('addToCart')
  const { userConfiguration, cart } = useMst()

  const handleAddToCartClick = async () => {
    cart.addToCart()
  }

  return (
    <Page>
      <Content>
        {cart.hasError || userConfiguration.hasUploadError ? (
          <ErrorModal>
            <img tw="m-auto" src={UFO} alt="UFO" />
            <Title tw="text-center">{t('weHaveAProblem')}</Title>
            <p>
              {cart.hasError
                ? cart.error
                : userConfiguration.hasUploadError
                ? t('couldNotUploadSomePhotos')
                : t('anErrorOccurred')}
            </p>
            <PrimaryButton tw="w-full" onClick={() => handleAddToCartClick()}>
              {t('retry')}
            </PrimaryButton>
          </ErrorModal>
        ) : (
          <Modal>
            <VerticalContents>
              <h2 tw="text-2xl font-bold text-center">{t('uploadInProgress')}</h2>
              <div>
                <Animation data={Rocket} />
              </div>
              <div tw="space-y-4">
                <p tw="text-teal-600 text-sm text-center">
                  {userConfiguration.isUploadCompleted
                    ? cart.isLoading
                      ? t('cartPreparationInProgress')
                      : t('uploadCompleted')
                    : t('photosPreparationInProgress')}
                </p>
                <ProgressBar progress={userConfiguration.uploadProgress} />
              </div>
              {DEBUG && cart.redirectUri && (
                <p tw="text-center">
                  <a href={cart.redirectUri}>DEBUG: Go to cart</a>
                </p>
              )}
            </VerticalContents>
          </Modal>
        )}
      </Content>
    </Page>
  )
})

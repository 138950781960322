import jsCookies from 'js-cookie'
import {
  AUTH_COOKIE,
  LANGUAGE_COOKIE,
  AVAILABLE_LANGUAGES,
  GDO_COOKIE,
  DEBUG,
  FORM_BOOLEAN_TRUE,
  FORM_BOOLEAN_FALSE,
  DEFAULT_LANGUAGE,
} from './consts'
import { CardTemplate } from './features/configuration/templates/CardTemplate'
import { ListTemplate } from './features/configuration/templates/ListTemplate'
import { PagerTemplate } from './features/configuration/templates/PagerTemplate'
import { LookupCard, LookupItemProps, LookupListItem, SquareLookupCard } from './components/LookupItems'
import TemplateCodeEnum from './models/Template'
import { PhotosiEditor } from './editor'

export const getAuthCookie = function (): string {
  return jsCookies.get(AUTH_COOKIE) || ''
}

export const removeAuthCookie = function () {
  jsCookies.remove(AUTH_COOKIE)
}

export function setAuthCookie(token: string) {
  jsCookies.set(AUTH_COOKIE, token)
}

export function getBrowserLanguage(): string | undefined {
  return navigator.language?.split('-')[0]
}

export function getPhotosiLanguage(): string | undefined {
  return jsCookies.get(LANGUAGE_COOKIE)
}

export function getLanguage(): string {
  const photosiLanguage = getPhotosiLanguage()

  if (typeof photosiLanguage !== 'undefined' && AVAILABLE_LANGUAGES.includes(photosiLanguage)) {
    return photosiLanguage
  }

  const browserLanguage = getBrowserLanguage()

  if (typeof browserLanguage !== 'undefined' && AVAILABLE_LANGUAGES.includes(browserLanguage)) {
    return browserLanguage
  }
  return AVAILABLE_LANGUAGES[0]
}

export function setLanguage(language: string) {
  jsCookies.set(LANGUAGE_COOKIE, language, { secure: true })
}

export const parseConfigurationQuery = function () {
  const params = new URLSearchParams(window.location.search)
  const configurationId = params.get('configurationId') || params.get('productType')
  const productId = params.get('productId')
  let lookups: { code: string; value: string }[] = []

  params.forEach((value, code) => {
    if (code !== 'configurationId' && code !== 'productType' && code !== 'productId') {
      lookups.push({ code, value })
    }
  })

  return {
    configurationId,
    productId,
    lookups,
  }
}

export const selectLookupTemplate = (
  templateCode: string | null
): [
  React.FC<{
    onSelect: (index: number) => () => void
  }>,
  React.FC<LookupItemProps>
] => {
  switch (templateCode) {
    case TemplateCodeEnum.LIST:
      return [ListTemplate, LookupListItem]
    case TemplateCodeEnum.PAGER:
      return [PagerTemplate, SquareLookupCard]
    default:
      return [CardTemplate, LookupCard]
  }
}

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function handleHttpError(e: any) {
  console.error('Error executing request', e)
}

export function getCountryFromLanguage(language: string) {
  if (['it', 'de', 'fr', 'nl', 'es'].includes(language)) return language
  return 'it' // TODO: check if it is a good idea
}

export function readFileAsync(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

export function getDistributor(): string {
  try {
    return JSON.parse(decodeURIComponent(jsCookies.get(GDO_COOKIE) || '')).distributorCode || ''
  } catch (e) {
    return ''
  }
}

export function fireEvent<
  ET extends keyof WindowEventMap,
  D extends WindowEventMap[ET] extends CustomEvent ? WindowEventMap[ET]['detail'] : undefined
>(eventType: ET, detail?: D) {
  const event = new CustomEvent<D>(eventType, { detail })
  window.dispatchEvent(event)
}

export function listenEvent<ET extends keyof WindowEventMap>(
  eventType: ET,
  handler: (event: WindowEventMap[ET]) => void
) {
  window.addEventListener(eventType, (event: WindowEventMap[ET]): void => {
    try {
      handler(event)
    } catch (error) {
      if (DEBUG) {
        throw error
      }
      console.error(`Error while handling event '${eventType}'`, error)
    }
  })
}

export type form_boolean = typeof FORM_BOOLEAN_TRUE | typeof FORM_BOOLEAN_FALSE

export const boolToRadio = (bool: boolean | undefined | null) => {
  return bool === true ? FORM_BOOLEAN_TRUE : bool === false ? FORM_BOOLEAN_FALSE : undefined
}

export function bestImageUrl(
  images: { url: string; tags: string[] }[],
  queryTags: string[],
  language = DEFAULT_LANGUAGE
): string | undefined {
  if (images.length === 0) return

  queryTags.push(`language=${language}`)

  const ratedImages = images.map((i) => ({
    url: i.url,
    score: queryTags.filter((t) => i.tags.map((t) => t.toLowerCase()).includes(t.toLowerCase())).length,
  }))
  const bestImage = ratedImages.reduce((current, prev) => (current.score > prev.score ? current : prev))

  return bestImage?.url
}

export async function generatePreviewUrl(file: File) {
  const { PhotosiEditorSDK } = await PhotosiEditor
  const { createThumbnail } = PhotosiEditorSDK.getUtility()
  const blob: Blob = await createThumbnail(file)
  return URL.createObjectURL(blob)
}

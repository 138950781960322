import React from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { BottomSheet, BottomSheetTitle } from './BottomSheet'
import { Button, ButtonGroup } from './Button'
import { Checkbox } from './forms/Checkbox'

type Props = {
  confirmationRequired: boolean
  onConfirmationChange: () => void
  onCancel: () => void
  onRemove: () => void
}

export const RemoveProductDialog: React.FC<Props> = ({
  confirmationRequired,
  onConfirmationChange,
  onCancel,
  onRemove,
}) => {
  const { t } = useTranslation('personalizeStep')

  return (
    <BottomSheet dismiss={onCancel}>
      <Content>
        <BottomSheetTitle>{t('ohNo')}</BottomSheetTitle>
        <p>{t('doYouConfirmRemoval')}</p>
        <p>
          <Checkbox checked={!confirmationRequired} onClick={onConfirmationChange}>
            <CheckboxText>{t('dontAskAgain')}</CheckboxText>
          </Checkbox>
        </p>
        <ButtonGroup>
          <Button block onClick={onRemove}>
            {t('remove')}
          </Button>
          <Button primary block onClick={onCancel}>
            {t('keep')}
          </Button>
        </ButtonGroup>
      </Content>
    </BottomSheet>
  )
}

const Content = tw.div`
  space-y-4
`

const CheckboxText = tw.span`
  text-teal-400
  text-sm
`

import React, { useState } from 'react'
import 'twin.macro'
import * as crypto from 'crypto'
import { useForm } from 'react-hook-form'

import { EMAIL_REGEX, FORM_BOOLEAN_FALSE, FORM_BOOLEAN_TRUE } from '../../consts'
import { register } from '../../api/auth'
import { useMst } from '../../models/RootStore'
import Form from '../../components/forms/Form'
import FormInput from '../../components/forms/FormInput'
import FormLabel from '../../components/forms/FormLabel'
import { PrimaryButton } from '../../components/Button'
import { SubTitle, Title } from '../../components/Title'
import { useCountry } from '../../hooks/useCountry'
import { useTranslation } from 'react-i18next'
import { Radio, RadioGroup } from '../../components/forms/Radio'
import { Close } from '../../components/Close'
import { FormError } from '../../components/forms/FormError'
import { CenterModal } from '../../components/Modal'
import { VerticalContents } from '../../components/VerticalContents'
import Confused from '../../assets/img/Confused.svg'
import { form_boolean } from '../../utils'

type FormTypes = {
  email: string
  password: string
  communication: form_boolean
  profiling: form_boolean
}

type Props = {
  reset: () => void
}

export const RegistrationForm: React.FC<Props> = ({ reset }) => {
  const { t } = useTranslation('registration')
  const { session, cart } = useMst()
  const [submitting, setSubmitting] = useState(false)
  const {
    register: registerField,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormTypes>()

  const email = registerField('email', {
    required: true,
    pattern: EMAIL_REGEX,
  })
  const password = registerField('password', { required: true })
  const communication = registerField('communication', { required: true })
  const profiling = registerField('profiling', { required: true })

  const [error, setError] = useState('')
  const [displayAlreadyUsed, setDisplayAlreadyUsed] = useState(false)
  const { country } = useCountry()

  const loading = submitting || !country

  const formFilled = !!email && !!password && communication !== undefined && profiling !== undefined

  const onSubmit = (data: FormTypes) => {
    setSubmitting(true)
    setError('')
    setTimeout(async () => {
      const accessToken = await register({
        email: data.email,
        password: data.password,
        country,
        selectedLanguage: session.selectedLanguage,
        authorizeMarketingComunications: data.communication === 'yes',
        authorizeBehaviorProfiling: data.profiling === 'yes',
      }).catch((e) => {
        console.error(e)
        if (e.alreadyUsed) {
          setDisplayAlreadyUsed(true)
        } else {
          setError(t('accountCreationError'))
        }
      })
      if (accessToken) {
        await session.login(accessToken)
        reset()
        cart.addToCart() // try adding to cart, if prerequisites are correct, TODO: emit a signal
      }
      setSubmitting(false)
    }, 1000)
  }

  const values = getValues()

  if (displayAlreadyUsed) {
    return (
      <CenterModal
        dismiss={() => {
          setDisplayAlreadyUsed(false)
        }}
      >
        <VerticalContents tw="text-center">
          <img src={Confused} alt="Confused phone" />
          <Title>{t('alreadyHaveAProfile')}</Title>
          <p>
            {t('emailAlreadyUsed')}
            <br />
            {t('tryWithAnotherEmail')}
          </p>
          <PrimaryButton
            tw="w-full"
            onClick={() => {
              setDisplayAlreadyUsed(false)
            }}
          >
            {t('useAnotherEmail')}
          </PrimaryButton>
        </VerticalContents>
      </CenterModal>
    )
  }

  return (
    <div>
      <div tw="grid grid-cols-title">
        <Close tw="place-self-start" onClick={reset} />
        <SubTitle>{t('registerWithEmail')}</SubTitle>
      </div>
      <Form tw="mt-4" onSubmit={handleSubmit(onSubmit)} noValidate>
        {error && <FormError tw="text-center">{error}</FormError>}
        <FormLabel>{t('email')}</FormLabel>
        <FormInput type="email" {...email} placeholder={t('emailPlaceholder')} disabled={submitting} />
        {errors.email && <FormError>{t('emailError')}</FormError>}
        <FormLabel>{t('password')}</FormLabel>
        <FormInput type="password" {...password} placeholder={t('passwordPlaceholder')} disabled={submitting} />
        {errors.password && <FormError>{t('passwordError')}</FormError>}
        <div tw="w-full">
          <FormLabel tw="normal-case">{t('gdprCommunications')}</FormLabel>

          <RadioGroup>
            <Radio
              type="radio"
              {...communication}
              checked={values.communication === FORM_BOOLEAN_FALSE}
              onClick={() => {
                setValue('communication', FORM_BOOLEAN_FALSE, { shouldDirty: true, shouldValidate: true })
              }}
            >
              {t('no')}
            </Radio>
            <Radio
              type="radio"
              {...communication}
              value="yes"
              checked={values.communication === FORM_BOOLEAN_TRUE}
              onClick={() => {
                setValue('communication', FORM_BOOLEAN_TRUE, { shouldDirty: true, shouldValidate: true })
              }}
            >
              {t('yes')}
            </Radio>
          </RadioGroup>
          {errors.communication && <FormError>{t('requiredError')}</FormError>}
          <FormLabel tw="normal-case">{t('gdprProfiling')}</FormLabel>
          <RadioGroup>
            <Radio
              type="radio"
              {...profiling}
              checked={values.profiling === FORM_BOOLEAN_FALSE}
              onClick={() => {
                setValue('profiling', FORM_BOOLEAN_FALSE, { shouldDirty: true, shouldValidate: true })
              }}
            >
              {t('no')}
            </Radio>
            <Radio
              type="radio"
              {...profiling}
              checked={values.profiling === FORM_BOOLEAN_TRUE}
              onClick={() => {
                setValue('profiling', FORM_BOOLEAN_TRUE, { shouldDirty: true, shouldValidate: true })
              }}
            >
              {t('yes')}
            </Radio>{' '}
          </RadioGroup>
          {errors.profiling && <FormError>{t('requiredError')}</FormError>}
        </div>
        <PrimaryButton type="submit" tw="w-full mt-16" disabled={loading || !formFilled}>
          {loading ? t('loading') : t('createAccount')}
        </PrimaryButton>
      </Form>
    </div>
  )
}

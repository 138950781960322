import React from 'react'
import { IStepOut } from '../../../models/Step'

type ReviewStepProps = {
  step: IStepOut
}

const ReviewStep: React.FC<ReviewStepProps> = ({ step }) => {
  return <div>{step.type}</div>
}
export default ReviewStep

export const IMAGE_SELECTION_VIEW: 'image_selection_view' = 'image_selection_view'
export const IMAGE_SELECTION_SOURCE_CLICK: 'image_selection_source_click' = 'image_selection_source_click'
export const IMAGES_SELECTION_IMAGES_SELECTED: 'image_selection_images_selected' = 'image_selection_images_selected'
export const LOOKUP_VIEW: 'lookup_view' = 'lookup_view'
export const LOOKUP_SELECT: 'lookup_select' = 'lookup_select'
export const PERSONALIZE_START: 'personalize_start' = 'personalize_start'
export const ADD_TO_CART: 'cart_add' = 'cart_add'
export const PERSONALIZE_ADD_PHOTOS: 'personalize_add_photo' = 'personalize_add_photo'
export const PERSONALIZE_REPLACE_IMAGE: 'personalize_replace_image' = 'personalize_replace_image'
export const PERSONALIZE_EDIT_IMAGE: 'personalize_edit_image' = 'personalize_edit_image'
export const PERSONALIZE_EDIT_CHANGE_FORMAT: 'personalize_edit_change_format' = 'personalize_edit_change_format'
export const PERSONALIZE_DELETE_PHOTO: 'personalize_delete_photo' = 'personalize_delete_photo'
export const MODIFY_PHOTO_VIEW: 'modify_photo_view' = 'modify_photo_view'
export const MODIFY_PHOTO_SELECT_FILTER: 'modify_photo_select_filter' = 'modify_photo_select_filter'
export const MODIFY_PHOTO_SELECT_TRANSFORM: 'modify_photo_select_transform' = 'modify_photo_select_transform'
export const MODIFY_PHOTO_FILTER_SAVE: 'modify_photo_filter_save' = 'modify_photo_filter_save'
export const MODIFY_PHOTO_TRANSFORM_SAVE: 'modify_photo_transform_save' = 'modify_photo_transform_save'
export const AUTH_LOGIN: 'auth_login' = 'auth_login'

declare global {
  interface WindowEventMap {
    [IMAGE_SELECTION_VIEW]: CustomEvent<{
      configurationId: string
      step: number
    }>
    [IMAGE_SELECTION_SOURCE_CLICK]: CustomEvent<{
      configurationId: string
      step: number
      sourceType: string
    }>
    [IMAGES_SELECTION_IMAGES_SELECTED]: CustomEvent<{
      configurationId: string
      step: number
      sourceType: string
      numberOfPhotos: number
    }>

    // Lookup events
    [LOOKUP_VIEW]: CustomEvent<{
      configurationId: string
      step: number
      stepCode: string
    }>
    [LOOKUP_SELECT]: CustomEvent<{
      configurationId: string
      step: number
      stepCode: string
      numberOfChoices: number
      lookUpId: string
    }>

    // Personalize events
    [PERSONALIZE_START]: Event
    [ADD_TO_CART]: Event
    [PERSONALIZE_ADD_PHOTOS]: Event
    [PERSONALIZE_REPLACE_IMAGE]: Event
    [PERSONALIZE_EDIT_IMAGE]: Event
    [PERSONALIZE_EDIT_CHANGE_FORMAT]: Event
    [PERSONALIZE_DELETE_PHOTO]: Event

    // PhotoSì editor events (names come from external lib, don't edit)
    [MODIFY_PHOTO_VIEW]: Event
    [MODIFY_PHOTO_SELECT_FILTER]: Event
    [MODIFY_PHOTO_SELECT_TRANSFORM]: Event
    [MODIFY_PHOTO_FILTER_SAVE]: Event
    [MODIFY_PHOTO_TRANSFORM_SAVE]: Event

    // Auth
    [AUTH_LOGIN]: CustomEvent<{
      userId: string
    }>
  }
}

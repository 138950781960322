import React from 'react'
import tw from 'twin.macro'
import { observer } from 'mobx-react-lite'

import { IButton } from '../models/Button'
import ButtonTypeEnum from '../models/ButtonType'
import { useMst } from '../models/RootStore'
import { IProduct } from '../models/Product'
import SvgPreview from './SvgPreview'

// Lookups will be enabled based on productConfiguration
const TIGHT_ENABLED_BUTTONS = [ButtonTypeEnum.REPLACE, ButtonTypeEnum.CHANGE_COLOR, ButtonTypeEnum.MOVE_PHOTO_CALENDAR]
const ENABLED_BUTTONS = [...TIGHT_ENABLED_BUTTONS, ButtonTypeEnum.EDIT]

type Props = {
  product: IProduct
  buttons: IButton[]
  onRemove: () => void
  onSwap: () => void
  handleLookupClick: (lookup: IButton) => void
  tight?: boolean
}

const ProductEditor: React.FC<Props> = ({ product, buttons, onSwap, onRemove, handleLookupClick, tight = true }) => {
  const { productConfiguration } = useMst()

  const enabledButtonTypes = tight ? TIGHT_ENABLED_BUTTONS : ENABLED_BUTTONS
  if (productConfiguration.enablePersonalizeLookups) {
    enabledButtonTypes.push(ButtonTypeEnum.LOOKUP)
  }
  const enabledButtons = buttons.filter((b) => {
    return enabledButtonTypes.includes(b.type)
  })

  const lazyLoadOffset = window.innerWidth / (tight ? 4 : 2)

  const currentLookupValues = buttons
    .filter((b) => b.type === ButtonTypeEnum.LOOKUP)
    .reduce((acc, b) => {
      if (!productConfiguration.data) console.log(b.lookupData.toJSON())
      acc[b.code] = b.lookupData.find(
        (ld) => productConfiguration.data && ld.id === productConfiguration.data[b.code]
      )?.name
      return acc
    }, {})

  const handleQuantityClick = (qty: number) => {
    product.changeQuantity(qty)
  }

  return (
    <div tw="mb-2" css={[tight && productConfiguration.isMultiPage && tw`grid col-gap-2 row-gap-2 grid-cols-2`]}>
      {product.sheets.map((sheet) => (
        <SvgPreview
          key={sheet.id}
          sheet={sheet}
          buttons={enabledButtons}
          lazyLoadOffset={lazyLoadOffset}
          lookups={currentLookupValues}
          verbose={!tight}
          quantity={product.quantity}
          onSwapClick={onSwap}
          onLookupClick={handleLookupClick}
          onQuantityClick={productConfiguration.allowMultipleProducts ? handleQuantityClick : undefined}
          onRemoveClick={productConfiguration.allowMultipleProducts ? onRemove : undefined}
        />
      ))}
    </div>
  )
}

export default observer(ProductEditor)

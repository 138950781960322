import React from 'react'
import ReactDOM from 'react-dom'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as Sentry from '@sentry/react'
import GTM from 'react-gtm-module'

import { DEBUG, DEFAULT_LANGUAGE, GTM_ID, SENTRY_DSN } from './consts'
import { getLanguage } from './utils'
import App from './App'
import GlobalStyles from './GlobalStyles'
import reportWebVitals from './reportWebVitals'

import it from './i18n/it.json'
import es from './i18n/es.json'

declare global {
  interface Window {
    PhotosiEditorSDK: any
    React: any
    ReactDOM: any
    rootStore: any
  }
}

window.PhotosiEditorSDK = window.PhotosiEditorSDK || {}

window.React = React
window.ReactDOM = ReactDOM

Sentry.init({ dsn: SENTRY_DSN })

const language = getLanguage()

i18n.use(initReactI18next).init({
  resources: {
    it,
    es,
  },
  lng: language,
  fallbackLng: DEFAULT_LANGUAGE,
  debug: DEBUG,
  react: {
    bindI18n: 'languageChanged loaded',
  },
})

/*
  Fix mobile viewport height
  */
let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

GTM.initialize({
  gtmId: GTM_ID,
})

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)

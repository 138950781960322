import { useEffect, useRef } from 'react'

import { useMst } from '../models/RootStore'

export const useBeforeUnload = () => {
  const { userConfiguration, cart } = useMst()

  const handler = useRef((event: BeforeUnloadEvent) => {
    if (!cart.redirectUri) {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }
    return false
  })

  return useEffect(() => {
    const currentHandler = handler.current

    if (userConfiguration.configurationId) {
      window.addEventListener('beforeunload', currentHandler)
    }

    return () => {
      window.removeEventListener('beforeunload', currentHandler)
    }
  }, [!!userConfiguration.configurationId])
}

import tw, { styled } from 'twin.macro'

export const BaseButton = tw.button`
  border border-gray-400
  bg-gray-200
  rounded-lg
  px-4 py-2
  focus:(outline-none)
  disabled:(opacity-50 cursor-default)
`

export const PrimaryButton = tw(BaseButton)`
  text-white font-medium
  border-teal-400
  bg-teal-400
  disabled:(bg-gray-400 border-gray-400)
`

export const SecondaryButton = tw(BaseButton)`
  text-teal-400
  border-teal-400
  bg-white
  disabled:(text-gray-400 border-gray-400)
`

export default BaseButton

type ButtonProps = {
  primary?: boolean
  block?: boolean
  fill?: boolean
}
export const Button = styled.button<ButtonProps>(({ primary, block, fill }) => [
  tw`
    px-4 py-2
    border
    rounded-lg
    font-medium
    focus:(outline-none)
  `,
  tw`
    text-teal-400
    border-teal-400
    bg-white
    disabled:(text-gray-400 border-gray-400)
  `,
  primary &&
    tw`
    text-white
    border-teal-400
    bg-teal-400
    disabled:(text-white bg-gray-400 border-gray-400)
  `,
  block && tw`w-full`,
  fill && tw`flex-grow`,
])

export const ButtonGroup = tw.div`
  w-full
  flex
  gap-4
`

import { useEffect, useRef } from 'react'

import { useMst } from '../models/RootStore'

export const useUnload = () => {
  const { userConfiguration, cart } = useMst()

  const unloadHandler = () => {
    if (userConfiguration.configurationId && !cart.redirectUri) {
      console.warn('Exited before completion, cleaning up...')
      userConfiguration.delete()
    }
  }
  const handler = useRef(unloadHandler)

  return useEffect(() => {
    const currentHandler = handler.current
    if (userConfiguration.configurationId) {
      window.addEventListener('unload', currentHandler)
    }

    return () => {
      window.removeEventListener('unload', currentHandler)
    }
  }, [!!userConfiguration.configurationId])
}

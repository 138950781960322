import React, { useRef, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { observer } from 'mobx-react-lite'
import { forceCheck } from 'react-lazyload'

import { PhotosiEditor } from '../../../editor'
import { useMst } from '../../../models/RootStore'
import { IStep } from '../../../models/Step'
import useDynamicPersonalization from '../../../hooks/useDynamicPersonalization'
import { Button, ButtonGroup } from '../../../components/Button'
import { Back } from '../../../components/Back'
import { IImageIn } from '../../../models/Image'
import SquareIcon from '../../../assets/img/icons/Square.svg'
import MosaicIcon from '../../../assets/img/icons/Mosaic.svg'
import CropIcon from '../../../assets/img/icons/Crop.svg'
import { CompositeHeader } from '../../../components/layout/Header'
import { Title } from '../../../components/Title'
import { Content } from '../../../components/layout/Content'
import { Footer } from '../../../components/layout/Footer'
import { useTranslation } from 'react-i18next'
import { usePageView } from '../../../hooks/usePageView'
import { fireEvent } from '../../../utils'
import * as events from '../../../events'
import { useProductConfigurations } from '../../../hooks/useProductConfigurations'
import { Loader } from '../../../components/Loader'
import { GenericError } from '../../../components/Error'
import { useUserConfiguration } from '../../../hooks/useUserConfiguration'
import { ToggleButton, ToggleItem } from '../../../components/Toggle'
import { IButton } from '../../../models/Button'
import { LookupSelector } from '../../../components/LookupSelector'
import { IProduct } from '../../../models/Product'
import ProductEditor from '../../../components/ProductEditor'
import { ImageSwapper } from '../../../components/ImageSwapper'
import { FrameConfirmationDialog } from '../../../components/FrameConfirmationDialog'
import { RemoveProductDialog } from '../../../components/RemoveProductDialog'

type Props = {
  step: IStep
}

const PersonalizeStep: React.FC<Props> = ({ step }) => {
  const { t } = useTranslation('personalizeStep')
  const {
    userConfiguration,
    productConfiguration,
    dynamicPersonalization,
    cart: { addToCart },
  } = useMst()
  const [displayGrid, setDisplayGrid] = useState<boolean>(false)
  const [EditorGlobalComponents, setEditorGlobalComponents] = useState<React.FC>()
  const editorComponents = useRef<HTMLDivElement>(null)
  const addImagesInput = useRef<HTMLInputElement>(null)

  const [productToSwap, setProductToSwap] = useState<IProduct | null>(null)
  const [productToRemove, setProductToRemove] = useState<IProduct | null>(null)
  const [showFrameConfirmation, setShowFrameConfirmation] = useState(false)
  const clearProductToRemove = () => {
    setProductToRemove(null)
  }
  const handleRemoveConfirmationRequiredChange = () => {
    userConfiguration.setRemoveImageConfirmRequired(!userConfiguration.removeImageConfirmRequired)
  }

  const [currentLookup, setCurrentLookup] = useState<IButton | null>(null)
  const clearCurrentLookup = () => {
    setCurrentLookup(null)
  }

  const selectLookup = (code: string, value: string) => {
    userConfiguration.setLookup({ code, value })
    clearCurrentLookup()
  }

  usePageView(() => {
    fireEvent(events.PERSONALIZE_START)
  })

  const imageCount = userConfiguration.usableImages.length
  const personalizationStatus = useDynamicPersonalization()
  const productConfigurationStatus = useProductConfigurations()
  const userConfigurationStatus = useUserConfiguration()

  const loading =
    productConfigurationStatus.loading ||
    !productConfiguration.dataSheets.length ||
    productConfigurationStatus.loading ||
    !dynamicPersonalization.productType

  const isError =
    productConfigurationStatus.isError || productConfigurationStatus.isError || userConfigurationStatus.isError

  useEffect(() => {
    forceCheck()
  }, [displayGrid])

  useEffect(() => {
    if (loading) return

    const loadEditor = async () => {
      const { photosiEditor } = await PhotosiEditor

      // Quick workaround to override PhotosiEditor colors
      // Will be refactored as soon as a proper setter will be available
      window.PhotosiEditorSDK.getConfig().backgroundPalette = productConfiguration.backgroundColors
      window.PhotosiEditorSDK.getConfig().textPalette = productConfiguration.textColors

      const globalComponents = await photosiEditor.globalComponents()
      setEditorGlobalComponents(globalComponents)

      userConfiguration.initProducts()
    }
    loadEditor()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, productConfiguration.productId])

  const handleAddImagesClick = () => {
    addImagesInput.current?.click()
    fireEvent(events.PERSONALIZE_ADD_PHOTOS)
  }

  const handleAddImagesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let images: IImageIn[] = []

    const { userImageFileType } = productConfiguration.dataSheets[0] // prints => single page products for now

    Array.prototype.forEach.call(e.target.files, (file: File) => {
      images.push({
        name: file.name,
        type: file.type,
        fileType: userImageFileType,
        file,
      })
    })
    userConfiguration.addImages(images)

    e.target.value = '' // Reset input, allow selecting again the same images over and over again
  }

  const handleAddToCartClick = () => setShowFrameConfirmation(true)
  const handleCheckFramingClick = () => setShowFrameConfirmation(false)
  const handleConfirmFramingClick = () => {
    userConfiguration.setPersonalization(true)
    fireEvent(events.ADD_TO_CART)
    step.next()
    addToCart()
  }

  const handleDisplayClick = () => {
    setDisplayGrid(!displayGrid)
  }

  const handleRemoveSelect = (product: IProduct): void => {
    if (userConfiguration.removeImageConfirmRequired) {
      setProductToRemove(product)
    } else {
      product.remove()
      clearProductToRemove()
    }
  }

  const handleRemoveClick = () => {
    productToRemove?.remove()
    clearProductToRemove()
  }

  return (
    <>
      <CompositeHeader>
        <Back handleBack={step.prev} />
        <Title>{t('personalize')}</Title>
        {productConfiguration.allowMultipleImages && (
          <ToggleButton tw="place-self-end" onClick={handleDisplayClick}>
            <ToggleItem active={!displayGrid}>
              <img src={SquareIcon} alt="List" />
            </ToggleItem>
            <ToggleItem active={displayGrid}>
              <img src={MosaicIcon} alt="Grid" />
            </ToggleItem>
          </ToggleButton>
        )}
      </CompositeHeader>
      {productConfigurationStatus.loading || personalizationStatus.loading ? (
        <Loader />
      ) : (
        <Content>
          {userConfiguration.sheets.length > 1 && (
            <div tw="flex items-center p-4 mx-4 mt-2 space-x-4 rounded bg-white shadow-md">
              <div tw="flex-none">
                <img tw="p-2 bg-green-100 rounded-full" src={CropIcon} alt="Crop" />
              </div>
              <div tw="text-gray-400 text-sm">{t('faceCentering')}</div>
            </div>
          )}
          <div
            tw="relative  mt-2"
            css={[displayGrid && !productConfiguration.isMultiPage && tw`grid-cols-2 grid col-gap-2 row-gap-2`]}
          >
            {userConfiguration.products.map((product) => (
              <ProductEditor
                key={product.id}
                product={product}
                buttons={dynamicPersonalization.buttons}
                onRemove={() => handleRemoveSelect(product)}
                onSwap={() => setProductToSwap(product)}
                handleLookupClick={setCurrentLookup}
                tight={displayGrid}
              />
            ))}
          </div>

          {productConfigurationStatus.isError ? (
            <GenericError retryHandler={productConfigurationStatus.retry} />
          ) : personalizationStatus.isError ? (
            <GenericError retryHandler={personalizationStatus.retry} />
          ) : userConfigurationStatus.isError ? (
            <GenericError retryHandler={userConfigurationStatus.retry} />
          ) : null}
        </Content>
      )}

      <div ref={editorComponents}>{EditorGlobalComponents}</div>

      {!isError && (
        <Footer>
          <div tw="text-sm mb-2 flex justify-between">
            <span>
              {(userConfiguration.usableImages.length > 1 ||
                userConfiguration.usableImages.length < userConfiguration.requiredImages) && (
                <span>
                  {imageCount}
                  {!userConfiguration.imagesUpload.isReady && <span>/{userConfiguration.requiredImages}</span>}{' '}
                  {t('selectedImages')}
                </span>
              )}
            </span>
            <span tw="font-bold">
              {loading || userConfigurationStatus.loading ? '...' : userConfiguration.publicPrice}
            </span>
          </div>
          <ButtonGroup>
            {productConfiguration.allowMultipleProducts && (
              <Button onClick={handleAddImagesClick}>{t('addPhotos')}</Button>
            )}
            <Button
              primary
              tw="flex-grow"
              disabled={loading || !userConfiguration.sheetsUpload.isReady || !userConfiguration.imagesUpload.isReady}
              onClick={handleAddToCartClick}
            >
              {t('addToCart')}
            </Button>
          </ButtonGroup>
          <input
            ref={addImagesInput}
            tw="hidden"
            type="file"
            accept="image/jpeg"
            id="fileInput"
            multiple
            disabled={!step.isActive}
            onChange={handleAddImagesChange}
          />
        </Footer>
      )}

      {showFrameConfirmation && (
        <FrameConfirmationDialog onCancel={handleCheckFramingClick} onConfirm={handleConfirmFramingClick} />
      )}
      {!!productToSwap && (
        <ImageSwapper
          sheets={productToSwap.sheets}
          onSwap={productToSwap.swapSheets}
          onDismiss={() => setProductToSwap(null)}
        />
      )}

      {productToRemove && (
        <RemoveProductDialog
          confirmationRequired={userConfiguration.removeImageConfirmRequired}
          onConfirmationChange={handleRemoveConfirmationRequiredChange}
          onCancel={clearProductToRemove}
          onRemove={handleRemoveClick}
        />
      )}
      {currentLookup && <LookupSelector {...currentLookup} dismiss={clearCurrentLookup} selectLookup={selectLookup} />}
    </>
  )
}
export default observer(PersonalizeStep)

import React from 'react'
import 'twin.macro'
import { CenterModal } from './Modal'
import { VerticalContents } from './VerticalContents'
import SweatSmile from '../assets/img/SweatSmile.svg'
import UFO from '../assets/img/UFO.svg'
import { Title } from './Title'
import { PrimaryButton } from './Button'
import { useTranslation } from 'react-i18next'

type Props = {
  message?: string
}

export const ErrorMessage: React.FC<Props> = ({ message }) => {
  return <div tw="m-4 text-red-600 text-center"> {message || 'An error occured'}</div>
}

export const ErrorModal: React.FC<Props> = ({ children }) => {
  return (
    <CenterModal>
      <VerticalContents tw="text-center">{children}</VerticalContents>
    </CenterModal>
  )
}

type GenericErrorProps = {
  retryHandler?: () => void
}

export const GenericError: React.FC<GenericErrorProps> = ({ retryHandler }) => {
  const { t } = useTranslation('error')
  return (
    <ErrorModal>
      {retryHandler ? <img src={UFO} alt="U.F.O." /> : <img src={SweatSmile} alt="Sweat smile" />}
      <Title>{t(retryHandler ? 'somethingWentWrong' : 'weHaveAProblem')}</Title>
      {!retryHandler && (
        <p>
          {t('forgiveUs')} {t('helpUs')}
        </p>
      )}
      {retryHandler && (
        <PrimaryButton tw="w-full" onClick={retryHandler}>
          {t('retry')}
        </PrimaryButton>
      )}
    </ErrorModal>
  )
}

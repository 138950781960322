import { flow } from 'mobx'
import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

import { MAX_RESOLUTION } from '../consts'
import { PhotosiEditor } from '../editor'
import { generatePreviewUrl } from '../utils'
import { BaseFile, IFile } from './File'

export const Image = types
  .compose(
    BaseFile,
    types
      .model({
        name: types.string,
        type: types.string,
        fileType: types.string,
        previewUrl: types.maybe(types.string),
      })
      .volatile<{ file?: File }>((self) => ({}))
      .actions((self) => ({
        setPreviewUrl(url: string) {
          self.previewUrl = url
        },
      }))
      .actions((self) => {
        const fileUpload: Function = (self as unknown as IFile).upload
        const fileSelf = self as unknown as IFile // Fuck MST types composition

        return {
          afterDestroy() {
            self.previewUrl && URL.revokeObjectURL(self.previewUrl)
          },
          setFile: flow(function* (file: File) {
            self.file = file
            const previewUrl = yield generatePreviewUrl(self.file)
            self.setPreviewUrl(previewUrl)
          }),
          getFile: flow(function* () {
            const { PhotosiEditorSDK } = yield PhotosiEditor
            const { imageToJpeg } = PhotosiEditorSDK.getUtility()
            const compressed = yield imageToJpeg(self.file, MAX_RESOLUTION)
            return compressed
          }),
          upload: flow(function* () {
            yield fileUpload({
              configurationFileType: self.fileType,
              quantity: 1,
              name: fileSelf.id + '.jpg',
            })
          }),
        }
      })
  )
  .named('Image')

export default Image

export interface IImage extends Instance<typeof Image> {}
export interface IImageIn extends SnapshotIn<typeof Image> {
  file: File
}
export interface IImageOut extends SnapshotOut<typeof Image> {}

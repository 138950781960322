import React from 'react'

const errorStyles = {
  border: '1px solid red',
  backgroundColor: 'coral',
  padding: '5px',
}

const UnknownStep: React.FC = () => {
  return <div style={errorStyles}>ERROR: unknown step</div>
}

export default UnknownStep

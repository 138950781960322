import React from 'react'
import tw from 'twin.macro'

const Banner = tw.div`
  fixed left-0 bottom-0
  w-full
  bg-black bg-opacity-75
  text-white text-center text-xs
  pointer-events-none
`

type Props = {
  trackingId: string
}

export const TrackingBanner: React.FC<Props> = ({ trackingId }) => {
  return <Banner>{trackingId}</Banner>
}

import { Instance, types } from 'mobx-state-tree'

export const Options = types
  .model({
    autoPreview: false,
    preloadOriginalImages: false,
    prioritizeFirstPreview: false,
  })
  .actions((self) => ({
    setAutoPreview(autoPreview: boolean) {
      self.autoPreview = autoPreview
    },
    setPreloadOriginalImages(preload: boolean) {
      self.preloadOriginalImages = preload
    },
    setPrioritizeFirstPreview(prioritize: boolean) {
      self.prioritizeFirstPreview = prioritize
    },
  }))

export interface IOptions extends Instance<typeof Options> {}

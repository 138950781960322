import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

import { Button } from './Button'

export const DynamicPersonalization = types.model({
  id: types.string,
  buttons: types.array(Button),
  productType: types.string,
  name: types.string,
})

export interface IDynamicPersonalization extends Instance<typeof DynamicPersonalization> {}
export interface IDynamicPersonalizationIn extends SnapshotIn<typeof DynamicPersonalization> {}
export interface IDynamicPersonalizationOut extends SnapshotOut<typeof DynamicPersonalization> {}

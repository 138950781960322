import { useContext, createContext } from 'react'
import { types, Instance, onSnapshot, cast, getRoot, IAnyStateTreeNode } from 'mobx-state-tree'

import { getLanguage } from '../utils'
import PageTypeEnum from './PageType'
import { IUserConfiguration, UserConfiguration } from './UserConfiguration'
import {
  IProductConfiguration,
  IProductConfigurationIn,
  ProductConfiguration,
  IFrozenProductConfiguration,
} from './ProductConfiguration'
import { IDynamicConfiguration, IDynamicConfigurationIn, DynamicConfiguration } from './DynamicConfiguration'
import { IDynamicPersonalization, IDynamicPersonalizationIn, DynamicPersonalization } from './DynamicPersonalization'
import { Session, ISession } from './Session'
import { Cart, ICart } from './Cart'
import { Options, IOptions } from './Options'

const RootStore = types
  .model({
    userConfiguration: UserConfiguration,
    productConfiguration: ProductConfiguration,
    availableProducts: types.array(types.frozen<IFrozenProductConfiguration>()),
    dynamicConfiguration: DynamicConfiguration,
    dynamicPersonalization: DynamicPersonalization,
    cart: Cart,
    session: Session,
    options: Options,
  })
  .views((self) => ({
    get currentPage() {
      if (!self.dynamicConfiguration.id || !self.userConfiguration.configurationId) {
        return PageTypeEnum.SETUP
      }
      if (self.dynamicConfiguration.steps.length > 0 && self.dynamicConfiguration.steps.every((s) => s.isCompleted)) {
        return PageTypeEnum.SAVE
      }
      return PageTypeEnum.CONFIGURE
    },
  }))
  .views((self) => ({
    get loginRequired() {
      return self.currentPage === PageTypeEnum.SAVE
    },
  }))
  .actions((self) => ({
    setProductConfiguration(productConfiguration: IProductConfigurationIn) {
      self.productConfiguration = cast(productConfiguration)
    },
    setAvailableProducts(products: IFrozenProductConfiguration[]) {
      self.availableProducts.replace(products)
    },
    setDynamicConfiguration(dynamicConfiguration: IDynamicConfiguration | IDynamicConfigurationIn) {
      self.dynamicConfiguration = cast(dynamicConfiguration)
      const initialLookups = Array.from(self.userConfiguration.lookups.keys())
      self.userConfiguration.skipSteps(initialLookups)
    },
    setDynamicPersonalization(dynamicPersonalization: IDynamicPersonalization | IDynamicPersonalizationIn) {
      self.dynamicPersonalization = cast(dynamicPersonalization)
    },
  }))

// Use handwritten interface to break circular dependancy, instead of:
// interface IRootStore extends Instance<typeof RootStore> {}
interface IRootStore {
  userConfiguration: IUserConfiguration
  productConfiguration: IProductConfiguration
  availableProducts: IProductConfiguration[]
  dynamicConfiguration: IDynamicConfiguration
  dynamicPersonalization: IDynamicPersonalization
  cart: ICart
  session: ISession
  options: IOptions
}
export const getRootStore = (self: IAnyStateTreeNode) => getRoot<IRootStore>(self)

let initialState = RootStore.create({
  session: {
    token: '',
    selectedLanguage: getLanguage(),
  },
  userConfiguration: {
    images: [],
    lookups: {},
  },
  availableProducts: [],
  productConfiguration: {
    productId: '',
    dataSheets: [],
  },
  dynamicConfiguration: {
    id: '',
    steps: [],
    configurationFilesUrl: '',
    userConfigurationsUrl: '',
    productConfigurationsUrl: '',
  },
  dynamicPersonalization: {
    id: '',
    buttons: [],
    productType: '',
    name: '',
  },
  cart: {},
  options: {},
})

/* Disabled to ease development
const data = localStorage.getItem('rootState');

if (data) {
  const json = JSON.parse(data);
  if (RootStore.is(json)) {
    initialState = RootStore.create(json);
  }
}
*/

export const rootStore = initialState
window.rootStore = rootStore // easy debugging

onSnapshot(rootStore, (snapshot) => {
  // console.log('Snapshot: ', snapshot)
  localStorage.setItem('rootState', JSON.stringify(snapshot))
})

export type RootInstance = Instance<typeof RootStore>
const RootStoreContext = createContext<null | RootInstance>(null)

export const RootStoreProvider = RootStoreContext.Provider
export function useMst(): Instance<typeof RootStore> {
  const store = useContext(RootStoreContext)
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider')
  }
  return store
}

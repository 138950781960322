import { v4 as uuid4 } from 'uuid'
import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

import { Sheet } from './Sheet'
import { getRootStore } from './RootStore'

export const Product = types
  .model({
    id: types.optional(types.identifier, uuid4),
    sheets: types.array(Sheet),
    quantity: 1,
  })
  .views((self) => ({
    allImagesSelected() {
      return self.sheets.every((s) => s.editor?.svgFeatures.userImagesCount === s.images.length)
    },
  }))
  .actions((self) => ({
    changeQuantity(qty: number) {
      self.quantity = Math.max(self.quantity + qty, 1)
    },
    swapSheets(from: string, to: string) {
      if (from === to) return // Same sheet, avoid doing anything (it also triggers a breaking bug on editor as of now)
      const fromSheet = self.sheets.find((s) => s.id === from)
      const toSheet = self.sheets.find((s) => s.id === to)
      if (!fromSheet || !toSheet) return
      const fromImages = [...fromSheet.images]
      const toImages = [...toSheet.images]
      fromSheet.images.replace(toImages)
      toSheet.images.replace(fromImages)

      // Update editors accordingly (single image support for now)
      if (!!toImages[0].file) {
        fromSheet.editor?.replace(toImages[0].file)
      } else {
        // as of now it would remove the whole editor, not just image
        // fromSheet.editor?.remove()
      }
      if (!!fromImages[0]?.file) {
        toSheet.editor?.replace(fromImages[0]?.file)
      } else {
        // as of now it would remove the whole editor
        // toSheet.editor?.remove()
      }
    },
    remove() {
      const { userConfiguration } = getRootStore(self)
      userConfiguration.removeProduct(self.id)
    },
  }))

export interface IProduct extends Instance<typeof Product> {}
export interface IProductIn extends SnapshotIn<typeof Product> {}
export interface IProductOut extends SnapshotOut<typeof Product> {}

import { when } from 'mobx'
import { cast, destroy, flow, getRoot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

import { deleteUserConfiguration, updateUserConfiguration } from '../api/products'
import { Image, IImage, IImageIn } from './Image'
import { Product } from './Product'
import { getRootStore } from './RootStore'
import { ISheet } from './Sheet'
import StepTypeEnum from './StepType'

export interface AddImage extends IImageIn {
  file: File
}

export const SelectedLookup = types.model({
  code: types.identifier,
  value: types.string,
})

export const UserConfiguration = types
  .model({
    images: types.array(Image),
    products: types.array(Product),
    lookups: types.map(SelectedLookup),
    configurationId: types.maybe(types.string),
    removeImageConfirmRequired: true,
    productViewId: types.maybe(types.string),
    productType: types.optional(types.string, ''), // TODO: save whole configuration locally
    productId: types.optional(types.string, ''), // TODO: save whole configuration locally
    publicPrice: types.optional(types.string, ''), // TODO: save whole configuration locally
    remoteQuantity: types.optional(types.number, 1), // TODO: save whole configuration locally
    personalization: false, // temp for debug
    cartUrl: '', // temp for debug
  })
  .views((self) => ({
    get sheets() {
      return self.products.reduce((sheets: ISheet[], product) => [...sheets, ...product.sheets], [])
    },
  }))
  .views((self) => ({
    get usableImages() {
      const { productConfiguration } = getRootStore(self)
      const maxUsableImages = productConfiguration.data
        ? Math.min(
            self.images.length,
            productConfiguration.data.maxConfigurableProducts * productConfiguration.data.maxImages
          )
        : 0

      return self.images.slice(0, maxUsableImages)
    },
    get usedImages() {
      return self.sheets.reduce((images: IImage[], sheet) => [...images, ...sheet.images], [])
    },
    get requiredImages() {
      const { productConfiguration } = getRootStore(self)
      return self.products.length * productConfiguration.requiredImages
    },
  }))
  .views((self) => ({
    get lookupFilters(): { [key: string]: string } {
      const filters: { [key: string]: string } = {}
      Object.values(self.lookups.toJSON()).forEach((e) => {
        filters[e.code] = e.value
      })
      return filters
    },
    get queryTags() {
      // Used to select most appropriate images for lookups
      const tags: string[] = []
      self.lookups.forEach((l) => {
        tags.push([l.code, l.value].join('='))
      })
      return tags
    },
    get personalizationReady(): boolean {
      // This assumes personalization is ready when all lookup steps before it
      // have been completed.
      // TODO: move this personalization ready/completed logic elsewhere since
      // it has nothing to do with UserConfiguration.
      const { dynamicConfiguration } = getRoot(self)
      for (let step of dynamicConfiguration.steps) {
        if (step.type === StepTypeEnum.PERSONALIZE) return true
        if (step.type === StepTypeEnum.LOOKUP && !step.isCompleted) return false
      }
      return true
    },
    get personalizationCompleted(): boolean {
      return !!self.configurationId && !!self.personalization
    },
    get imagesCount(): number {
      return self.images.length
    },
    get quantity(): number {
      return self.products.reduce((acc, curr) => acc + curr.quantity, 0)
    },
    get hasUploadError() {
      const hasError = !!self.usedImages.find((img) => img.isError) || !!self.sheets.find((sheet) => sheet.isError)
      return hasError
    },
    get imagesUpload() {
      const isReady: boolean =
        self.usedImages.length >= self.requiredImages && self.products.every((p) => p.allImagesSelected())
      const isCompleted: boolean =
        self.usedImages.length > 0 && self.usedImages.every((img) => !!img.configurationFile?.uploaded)
      const uploadedCount = self.usedImages.filter((img) => img.configurationFile?.uploaded).length
      const progress = uploadedCount / self.usedImages.length
      return {
        isReady,
        isCompleted,
        uploadedCount,
        progress,
      }
    },
    get sheetsUpload() {
      const isReady: boolean = self.sheets.every((sheet) => !!sheet.editor)
      const isCompleted: boolean =
        self.sheets.length > 0 && self.sheets.every((sheet) => !!sheet.configurationFile?.uploaded)
      const uploadedCount = self.sheets.filter((sht) => sht.configurationFile?.uploaded).length
      const progress = uploadedCount / self.sheets.length
      return {
        isReady,
        isCompleted,
        uploadedCount,
        progress,
      }
    },
  }))
  .views((self) => ({
    get isUploadCompleted() {
      return self.imagesUpload.isCompleted && self.sheetsUpload.isCompleted
    },
    get uploadProgress() {
      const totalUploads = self.usedImages.length + self.sheets.length
      const completedUploads = self.imagesUpload.uploadedCount + self.sheetsUpload.uploadedCount
      return totalUploads ? completedUploads / totalUploads : 0
    },
    get freeImages() {
      return self.images.filter((img) => !self.usedImages.includes(img))
    },
  }))
  .actions((self) => ({
    paginateImages: flow(function* (images: IImage[]) {
      const imagesToPaginate = [...images]

      for (let sheet of self.sheets) {
        // TODO: Move logic to sheet itself and find an elegant way to make sheet consume some images without knowing beforehand how many it will consume
        if (imagesToPaginate.length === 0) return // no images to associate

        yield when(() => !!sheet.editor)
        const missingImages = Math.max(sheet.svgFeatures.userImagesCount - sheet.images.length, 0)
        if (missingImages === 0) continue

        const imagesForThisSheet = [...sheet.images, ...imagesToPaginate.splice(0, missingImages)]
        sheet.setImages(imagesForThisSheet)
      }
    }),
  }))
  .actions((self) => ({
    setImages(images: IImageIn[]) {
      // Clean up images references before sestroyIng old ones
      // TODO: evaluate if sobstituting old ones keeping ids and reference is feasibe (and desired)
      self.sheets.forEach((s) => (s.images = cast([])))

      self.images.replace(images.map((img) => cast(img)))

      self.images.forEach((img, index) => {
        img.setFile(images[index].file)
      })

      self.images.forEach(async (img, i) => {
        setTimeout(img.upload, 100 * i) // Add some delay between each upload (TODO: use real debounce)
      })
    },
    removeImage(image: IImage) {
      self.images.splice(self.images.indexOf(image), 1)
    },
    setRemoveImageConfirmRequired(required: boolean) {
      self.removeImageConfirmRequired = required
    },
    initProducts() {
      const { productConfiguration } = getRootStore(self)
      if (!productConfiguration.data) {
        return
      }

      // Usable images already take max products into account
      const numberOfProducts = Math.ceil(self.usableImages.length / productConfiguration.data.maxImages)

      // Slice existing products, ensuring they don't exceed max.
      // Try to use old sheets if lenght matches datasheets', datasheet info are now copied over
      const products = self.products
        .slice(0, numberOfProducts)
        .filter((p) => p.sheets.length === productConfiguration.dataSheets.length)
      self.products.replace(products)
      self.products.forEach((product) => {
        productConfiguration.dataSheets.forEach((dataSheet, index) => {
          const sheet = product.sheets[index]
          const oldSheetId = sheet.dataSheet.id
          sheet.setDataSheet({ ...dataSheet })
          if (oldSheetId !== dataSheet.id) {
            sheet.refreshEditor()
          }
        })
      })

      // Add products until max is reached or all images already have a place
      while (self.products.length < numberOfProducts) {
        const sheets = productConfiguration.dataSheets.map((dataSheet, orderIndex) => {
          return {
            images: [],
            dataSheet: { ...dataSheet },
            orderIndex,
          }
        })
        self.products.push(Product.create({ sheets }))
      }

      self.paginateImages(self.freeImages)
    },
    removeProduct(productId: string) {
      // TODO: use product instead of productId, which is not working just on this specific model
      const p = self.products.find((p) => p.id === productId)
      if (!p) return
      p.sheets.forEach((s) => s.images.forEach((i) => destroy(i)))
      destroy(p)
    },
    addImage(image: AddImage) {
      const img = Image.create(image)
      self.images.push(img)
      img.upload()
      return img
    },
    setLookup(lookup: SnapshotIn<typeof SelectedLookup> | Instance<typeof SelectedLookup>) {
      self.lookups.put(lookup)
    },
    skipSteps(initialLookups: string[]) {
      const { dynamicConfiguration } = getRoot(self)
      dynamicConfiguration.steps.forEach((step) => {
        console.log(step.code, { step })
        if (initialLookups.includes(step.code)) {
          step.setConfirmed(true)
        }
      })
    },
    removeLookup(id: string) {
      self.lookups.delete(id)
    },
    setProductType(productType: string) {
      self.productType = productType
    },
    setProductId(productId: string) {
      self.productId = productId
    },
    setProductViewId(productViewId: string) {
      self.productViewId = productViewId
    },
    setConfigurationId(configurationId: string) {
      self.configurationId = configurationId
    },
    setPublicPrice(publicPrice: string) {
      self.publicPrice = publicPrice
    },
    setRemoteQuantity(quantity: number) {
      self.remoteQuantity = quantity
    },
    setPersonalization(personalization: boolean) {
      self.personalization = personalization
    },
    setCartUrl(cartUrl: string) {
      self.cartUrl = cartUrl
    },
    reset() {
      self.images.replace([])
      self.lookups.replace({})
      self.personalization = false
      self.cartUrl = ''
    },
    delete() {
      if (!self.configurationId) return
      const { dynamicConfiguration } = getRoot(self)
      const configurationsUrl = dynamicConfiguration.userConfigurationsUrl
      if (!configurationsUrl) return
      deleteUserConfiguration(configurationsUrl, self.configurationId)
    },
  }))
  .actions((self) => ({
    addImages(images: IImageIn[]) {
      const imagesToPaginate: IImage[] = []
      images.forEach(({ file, ...imgData }, i) => {
        const image = Image.create(imgData)
        image.setFile(file)
        self.images.push(image)
        imagesToPaginate.push(image)
        setTimeout(image.upload, 100 * i) // Add some delay between each upload (TODO: use real debounce)
      })

      self.initProducts()
      self.paginateImages(imagesToPaginate)
    },

    uploadSheets: flow(function* () {
      if (!self.configurationId) {
        console.warn('Cannot upload configuration without configurationId')
        return
      }
      const { dynamicConfiguration, session } = getRoot(self)
      updateUserConfiguration(
        dynamicConfiguration.userConfigurationsUrl,
        self.configurationId,
        {
          quantity: self.quantity,
        },
        session.selectedLanguage
      )
      yield Promise.all(self.sheets.map(async (sheet) => await sheet.upload()))
      console.log('Sheets upload completed')
    }),
    uploadImages: flow(function* () {
      // Quick hack to ease re-trying upload
      yield Promise.all(self.images.map(async (image) => await image.upload()))
      console.log('Images upload completed')
    }),
  }))

export interface IUserConfiguration extends Instance<typeof UserConfiguration> {}
export interface IUserConfigurationIn extends SnapshotIn<typeof UserConfiguration> {}
export interface IUserConfigurationOut extends SnapshotOut<typeof UserConfiguration> {}

export interface ISelectedLookupIn extends SnapshotIn<typeof SelectedLookup> {}

import { useCallback, useEffect, useState } from 'react'
import { getDynamicPersonalization } from '../api/dynamicConfigurations'
import { useMst } from '../models/RootStore'
import { handleHttpError } from '../utils'

const useDynamicPersonalization = () => {
  const {
    productConfiguration: { productId },
    setDynamicPersonalization,
    session: { selectedLanguage },
  } = useMst()
  const [loading, setLoading] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [isError, setIsError] = useState(false)

  const callback = useCallback(async () => {
    if (!productId) return
    if (fetching) return // Avoid multiple concurrent calls

    setFetching(true)
    setLoading(true)
    setIsError(false)

    const dynamicPersonalization = await getDynamicPersonalization(productId, selectedLanguage).catch(handleHttpError)
    if (!dynamicPersonalization) {
      setIsError(true)
    } else {
      setDynamicPersonalization({
        ...dynamicPersonalization.data,
        id: dynamicPersonalization.data.toString(),
      })
    }
    setFetching(false)
    setLoading(false)
  }, [productId, selectedLanguage])

  useEffect(() => {
    callback()
  }, [callback])

  return {
    loading,
    isError,
    retry: callback,
  }
}

export default useDynamicPersonalization

import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const Wrapper = styled(tw.div`
  w-full
  border-b border-gray-200
  text-center
  text-xs
`)`
  line-height: 1px;
`

const Inner = tw.span`
  px-2
  bg-white
  text-gray-400 uppercase
`

export const Divider: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <Inner>{children}</Inner>
    </Wrapper>
  )
}

import React from 'react'
import 'twin.macro'

import { fireEvent, selectLookupTemplate } from '../../../utils'
import * as events from '../../../events'
import useLookup from '../../../hooks/useLookup'
import { IStep } from '../../../models/Step'
import { CompositeHeader } from '../../../components/layout/Header'
import { Content } from '../../../components/layout/Content'
import { Title } from '../../../components/Title'
import { Loader } from '../../../components/Loader'
import { GenericError } from '../../../components/Error'
import { Back } from '../../../components/Back'
import { usePageView } from '../../../hooks/usePageView'
import { useMst } from '../../../models/RootStore'

type Props = {
  step: IStep
}

const LookupStep: React.FC<Props> = ({ step }) => {
  const { dynamicConfiguration, userConfiguration } = useMst()
  const [Template, CardType] = selectLookupTemplate(step.templateCode) // Naïve: only one template for now, needs to be matched
  // All Lookup step types have lookupUrl, but not all other steps do.
  // Damn you, Typescript! >:{
  const lookupUrl: string = step.lookupUrl as string

  usePageView(() => {
    fireEvent(events.LOOKUP_VIEW, {
      configurationId: dynamicConfiguration.id,
      step: step.id,
      stepCode: step.code,
    })
  }, [step.id])

  const { choices, prices, loading, isError, getChoices } = useLookup(step.code, lookupUrl)

  const choicesData = step.lookupData
    .filter((ld) => !!choices.find((c) => c.id === ld.id))
    .map((lookupData) => {
      return {
        id: lookupData.id,
        lookupData: {
          name: lookupData?.name || lookupData.name,
          description: lookupData?.alternativeName,
          image: lookupData?.bestImage,
          price: step.showPrices ? prices[lookupData.id] : undefined,
        },
      }
    })

  const handleSelect = (index: number) => () => {
    const value = choicesData[index].id
    userConfiguration.setLookup({ code: step.code, value })

    fireEvent(events.LOOKUP_SELECT, {
      configurationId: dynamicConfiguration.id,
      step: step.id,
      stepCode: step.code,
      numberOfChoices: choices.length,
      lookUpId: value,
    })
    step.next()
  }

  return (
    <>
      <CompositeHeader>
        {step.hasPrev ? <Back handleBack={step.prev} /> : <span></span>}
        <Title>{step.title}</Title>
      </CompositeHeader>
      {loading ? (
        <Loader />
      ) : isError ? (
        <GenericError retryHandler={getChoices} />
      ) : (
        <Content tw="h-full">
          <Template onSelect={handleSelect}>
            {choicesData.map(({ id, lookupData }, index) => (
              <div key={id} onClick={handleSelect(index)}>
                <CardType {...lookupData} />
              </div>
            ))}
          </Template>
        </Content>
      )}
    </>
  )
}

export default LookupStep

import React from 'react'
import { observer } from 'mobx-react-lite'

import { IStep } from '../../models/Step'
import ImageSelectionStep from './steps/ImageSelectionStep'
import UnknownStep from './steps/UnknownStep'
import LookupStep from './steps/LookupStep'
import ReviewStep from './steps/ReviewStep'
import PersonalizeStep from './steps/PersonalizeStep'
import StepTypeEnum from '../../models/StepType'

type DynamicStepProps = {
  step: IStep
}

const DynamicStep: React.FC<DynamicStepProps> = ({ step }) => {
  if (!step.isActive) return null

  return step.type === StepTypeEnum.IMAGE_SELECTION ? (
    <ImageSelectionStep step={step} />
  ) : step.type === StepTypeEnum.LOOKUP ? (
    <LookupStep step={step} />
  ) : step.type === StepTypeEnum.REVIEW ? (
    <ReviewStep step={step} />
  ) : step.type === StepTypeEnum.PERSONALIZE ? (
    <PersonalizeStep step={step} />
  ) : (
    <UnknownStep />
  )
}

export default observer(DynamicStep)

import { API_BASE_URL, PRODUCTS_API_HEADERS } from '../consts'
import { IFrozenProductConfiguration } from '../models/ProductConfiguration'

export async function getProducts(
  url: string,
  params: { [key: string]: string },
  language: string
): Promise<IFrozenProductConfiguration[]> {
  const queryParams = new URLSearchParams(params).toString()
  const pagination = '&per_page=100'

  const response = await fetch(`${url}?${queryParams}${pagination}`, {
    headers: {
      ...PRODUCTS_API_HEADERS,
      'Accept-Language': language,
    },
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response)
}

export type LookupResponse = {
  name: string
  id: string
}

export async function getLookups(
  url: string,
  params: { [key: string]: string } | string,
  language: string
): Promise<LookupResponse[]> {
  const queryParams = new URLSearchParams(params).toString()

  const glue = url.includes('?') ? '&' : '?'
  const pagination = '&per_page=20'

  const response = await fetch(`${url}${glue}${queryParams}${pagination}`, {
    headers: {
      ...PRODUCTS_API_HEADERS,
      'Accept-Language': language,
    },
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response)
}

type UserConfigurationRequestBody = {
  productId?: string
  quantity?: number
}

export async function createUserConfiguration(url: string, body: UserConfigurationRequestBody, language: string) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...PRODUCTS_API_HEADERS,
      'Accept-Language': language,
    },
    body: JSON.stringify(body),
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response)
}

export async function updateUserConfiguration(
  url: string,
  id: string,
  body: UserConfigurationRequestBody,
  language: string,
  token: string = ''
) {
  const headers = {
    ...PRODUCTS_API_HEADERS,
    'Accept-Language': language,
  }
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  const response = await fetch(`${url}/${id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response)
}

export async function deleteUserConfiguration(url: string, id: string) {
  const response = await fetch(`${url}/${id}`, {
    method: 'DELETE',
    keepalive: true,
    headers: {
      ...PRODUCTS_API_HEADERS,
    },
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response)
}

export type DataSheetResponse = {
  description: string
  id: string
  readyToPrintFileType: string
  svgFileType: string
  svgPreview: string
  svgPreviewUrl: string
  svgProduction: string
  svgProductionUrl: string
  userImageFileType: string
}
export async function getDataSheet(path: string, baseUrl?: string): Promise<DataSheetResponse> {
  const response = await fetch(baseUrl || API_BASE_URL + path, {
    headers: PRODUCTS_API_HEADERS,
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response)
}

export async function getDataSheets(paths: string[]): Promise<DataSheetResponse[]> {
  return Promise.all<DataSheetResponse>(paths.map((ds) => getDataSheet(ds)))
}

type SvgResponse = {
  outputWidth: number
  outputHeight: number
}

export async function getSvg(path: string, baseUrl?: string): Promise<SvgResponse> {
  const response = await fetch(baseUrl || API_BASE_URL + path, {
    headers: PRODUCTS_API_HEADERS,
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response)
}

export async function createUserConfigurationFile(url: string, body: any) {
  // TODO: body type
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      ...PRODUCTS_API_HEADERS,
    },
    body: JSON.stringify(body),
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response)
}

export async function updateUserConfigurationFile(url: string, id: string, body: any) {
  // TODO: body type
  const response = await fetch(`${url}/${id}`, {
    method: 'PUT',
    headers: {
      ...PRODUCTS_API_HEADERS,
    },
    body: JSON.stringify(body),
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response)
}

import React, { useState } from 'react'
import 'twin.macro'
import { observer } from 'mobx-react-lite'

import { useMst } from '../../models/RootStore'
import { PrimaryButton } from '../../components/Button'
import { Link } from '../../components/Link'
import { BottomSheet } from '../../components/BottomSheet'
import { useLogin } from '../../hooks/useLogin'
import Camera from '../../assets/img/Camera.svg'
import { LoginForm } from './LoginForm'
import { RegistrationForm } from './RegistrationForm'
import { Divider } from '../../components/Divider'
import { SubTitle } from '../../components/Title'
import { VerticalContents } from '../../components/VerticalContents'
import { useTranslation } from 'react-i18next'
import { CompleteProfileForm } from './CompleteProfileForm'

export const Authentication: React.FC = observer(() => {
  const { t } = useTranslation('auth')
  const { loginRequired, session } = useMst()
  const [displayLogin, setDisplayLogin] = useState(false)
  const [displayRegistration, setDisplayRegistration] = useState(false)
  const displayCompleteProfile = session.user && !session.user.isProfileCompleted

  useLogin()

  const handleLogoutClick = () => {
    session.logout()
  }

  if (!loginRequired) return null

  if (displayCompleteProfile) {
    return (
      <BottomSheet>
        <CompleteProfileForm />
      </BottomSheet>
    )
  }

  if (session.isAuthenticated) return null

  const handleLoginCLick = () => {
    setDisplayLogin(true)
    setDisplayRegistration(false)
  }

  const handleRegisterCLick = () => {
    setDisplayLogin(false)
    setDisplayRegistration(true)
  }

  const handleResetClick = () => {
    setDisplayLogin(false)
    setDisplayRegistration(false)
  }

  if (displayLogin) {
    return (
      <BottomSheet>
        <LoginForm reset={handleResetClick} />
      </BottomSheet>
    )
  }

  if (displayRegistration) {
    return (
      <BottomSheet>
        <RegistrationForm reset={handleResetClick} />
      </BottomSheet>
    )
  }

  return (
    <BottomSheet>
      <VerticalContents tw="p-4">
        <img src={Camera} alt="Camera" />
        <SubTitle>{t('printYourEmotions')}</SubTitle>
        <PrimaryButton tw="w-full" onClick={handleRegisterCLick}>
          {t('registerWithEmail')}
        </PrimaryButton>
        <Divider>{t('alreadyHaveAProfile')}</Divider>
        <Link tw="font-semibold" onClick={handleLoginCLick}>
          {t('login')}
        </Link>
      </VerticalContents>
    </BottomSheet>
  )
})

import React, { useEffect, useState } from 'react'
import 'twin.macro'

import { DEBUG } from '../../consts'
import { useMst } from '../../models/RootStore'
import Button from '../../components/Button'
import { parseConfigurationQuery } from '../../utils'
import { Loader } from '../../components/Loader'
import { LanguageSelector } from '../../components/LanguageSelector'
import { OptionsSelector } from '../../components/OptionsSelector'
import { Content } from '../../components/layout/Content'
import { Header } from '../../components/layout/Header'
import { Page } from '../../components/layout/Page'
import { Title } from '../../components/Title'
import { observer } from 'mobx-react-lite'
import { useErrorHandler } from 'react-error-boundary'
import { GenericError } from '../../components/Error'
import useDynamicConfiguration from '../../hooks/useDynamicConfiguration'
import { useProductConfigurations } from '../../hooks/useProductConfigurations'
import { useUserConfiguration } from '../../hooks/useUserConfiguration'
import { TestLinks } from '../../components/TestLinks'

const ProductTypeSelector: React.FC = () => {
  const { userConfiguration, session } = useMst()
  const [configurationId, setConfigurationId] = useState('')
  const [criticalError, setCriticalError] = useState<Error | undefined>()
  const [recoverableError, setRecoverableError] = useState<boolean>(false)
  useErrorHandler(criticalError)

  const parsed = parseConfigurationQuery()

  const displayFeatured = DEBUG || session.user?.isStaff

  useEffect(() => {
    if (parsed.configurationId) {
      setConfigurationId(parsed.configurationId)
    }
    userConfiguration.setProductViewId(parsed.productId || '')
    parsed.lookups.forEach((lookup) => {
      userConfiguration.setLookup(lookup)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dynamicConfiguration = useDynamicConfiguration(configurationId)
  const productConfigurationStatus = useProductConfigurations()
  const userConfigurationStatus = useUserConfiguration()

  const loading =
    dynamicConfiguration.loading ||
    (configurationId && (productConfigurationStatus.loading || userConfigurationStatus.loading))

  const handleGenerateErrorClick = () => {
    setCriticalError(new Error('Test sentry integration'))
  }
  const handleDisplayRecoverableErrorClick = () => {
    setRecoverableError(true)
  }

  if (recoverableError) {
    return <GenericError retryHandler={() => setRecoverableError(false)} />
  }
  if (loading || !displayFeatured) {
    return (
      <Page>
        <Content>
          <Loader />
        </Content>
      </Page>
    )
  }
  return (
    <Page>
      <Header>
        <Title tw="text-center">Select product type</Title>
      </Header>
      <Content tw="p-4">
        <div>
          {dynamicConfiguration.isError ? (
            <GenericError retryHandler={dynamicConfiguration.retry} />
          ) : productConfigurationStatus.isError ? (
            <GenericError retryHandler={productConfigurationStatus.retry} />
          ) : (
            <TestLinks />
          )}

          <LanguageSelector />
          <OptionsSelector />
          <Button tw="w-full mb-4 p-4" onClick={handleGenerateErrorClick}>
            Test errore non recuperabile
          </Button>
          <Button tw="w-full mb-4 p-4" onClick={handleDisplayRecoverableErrorClick}>
            Test errore recuperabile
          </Button>
        </div>
      </Content>
    </Page>
  )
}

export default observer(ProductTypeSelector)

import { Instance, SnapshotIn, SnapshotOut, types, getRoot } from 'mobx-state-tree'
import { bestImageUrl } from '../utils'
import { getRootStore } from './RootStore'

import StepTypeEnum from './StepType'
import TemplateCodeEnum from './Template'

const StepLookupImage = types.model({
  url: types.string,
  tags: types.array(types.string),
})

const StepLookup = types
  .model({
    images: types.array(StepLookupImage),
    name: types.string,
    id: types.string,
    alternativeName: types.maybeNull(types.string),
  })
  .views((self) => ({
    get bestImage() {
      const {
        userConfiguration: { queryTags },
        session: { selectedLanguage },
      } = getRootStore(self)
      return bestImageUrl(self.images.toJSON(), queryTags, selectedLanguage)
    },
  }))

export const Step = types
  .model({
    id: types.number,
    code: types.string,
    type: types.enumeration<StepTypeEnum>('StepType', Object.values(StepTypeEnum)),
    title: types.string,
    lookupData: types.array(StepLookup),
    lookupUrl: types.maybeNull(types.string),
    ctaLabel: types.maybeNull(types.string),
    templateCode: types.maybeNull(types.enumeration<TemplateCodeEnum>('TemplateCode', Object.values(TemplateCodeEnum))),
    showPrices: types.boolean,
    confirmed: false, // User explicit action
  })
  .views((self) => ({
    get isCompleted(): boolean {
      // Both user action and values set in store (for some )
      if (!self.confirmed) {
        // try to short circuit any further calculations
        return false
      }
      const { userConfiguration } = getRoot(self)

      switch (self.type) {
        case StepTypeEnum.IMAGE_SELECTION:
          return userConfiguration.images.length > 0
        case StepTypeEnum.LOOKUP:
          return !!userConfiguration.lookups.toJSON()[self.code]
      }
      return self.confirmed
    },
    get isActive(): boolean {
      const { dynamicConfiguration } = getRoot(self)
      return dynamicConfiguration.currentStep.code === self.code
    },
    get previous(): IStep | undefined {
      const {
        dynamicConfiguration: { steps },
      } = getRoot(self)
      const index = steps.indexOf(self)
      if (!index) return undefined
      return steps[index - 1]
    },
  }))
  .views((self) => ({
    get hasPrev(): boolean {
      const prev = self.previous
      return !!prev && prev.type !== StepTypeEnum.IMAGE_SELECTION
    },
  }))
  .actions((self) => ({
    setConfirmed(confirmed: boolean) {
      self.confirmed = confirmed
    },
  }))
  .actions((self) => ({
    undo() {
      if (self.type === StepTypeEnum.LOOKUP) {
        const { userConfiguration } = getRoot(self)
        userConfiguration.removeLookup(self.code)
      }
      self.setConfirmed(false)
    },
  }))
  .actions((self) => ({
    prev() {
      self.previous?.undo()
    },
    next() {
      self.setConfirmed(true)
    },
  }))

export interface IStep extends Instance<typeof Step> {}
export interface IStepIn extends SnapshotIn<typeof Step> {}
export interface IStepOut extends SnapshotOut<typeof Step> {}

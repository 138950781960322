import React from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { Button, ButtonGroup } from './Button'
import { CenterModal, ModalText, ModalTitle } from './Modal'
import { VerticalContents } from './VerticalContents'
import Cards from '../assets/img/Cards.svg'

type Props = {
  onCancel: () => void
  onConfirm: () => void
}

export const FrameConfirmationDialog: React.FC<Props> = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation('personalizeStep')

  return (
    <CenterModal>
      <Content>
        <img src={Cards} alt="photos" />
        <ModalTitle>{t('didYouCheckEverything')}</ModalTitle>
        <ModalText>{t('areImagesFramedCorrectly')}</ModalText>
        <ButtonGroup>
          <Button block onClick={onCancel}>
            {t('reviewFraming')}
          </Button>
          <Button block primary onClick={onConfirm}>
            {t('confirmFraming')}
          </Button>
        </ButtonGroup>
      </Content>
    </CenterModal>
  )
}

const Content = tw(VerticalContents)`
  w-full
  space-y-4
  text-center
`

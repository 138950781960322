import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

import ButtonTypeEnum from './ButtonType'

const StepLookupImage = types.model({
  url: types.string,
  tags: types.array(types.string),
})

const StepLookup = types.model({
  images: types.array(StepLookupImage),
  name: types.string,
  id: types.string,
  alternativeName: types.maybeNull(types.string),
})

export const Button = types
  .model({
    lookupUrl: types.string,
    id: types.number,
    icon: types.maybeNull(types.string),
    lookupData: types.array(StepLookup),
    showPrices: types.boolean,
    type: types.enumeration<ButtonTypeEnum>('ButtonType', Object.values(ButtonTypeEnum)),
    title: types.string,
    code: types.string,
  })
  .views((self) => ({}))

export interface IButton extends Instance<typeof Button> {}
export interface IButtonIn extends SnapshotIn<typeof Button> {}
export interface IButtonOut extends SnapshotOut<typeof Button> {}

enum ButtonTypeEnum {
  EDIT = 'EDIT',
  REPLACE = 'REPLACE',
  CHANGE_COLOR = 'CHANGE_COLOR',
  QUANTITY = 'QUANTITY',
  LOOKUP = 'LOOKUP',
  SHUFFLE = 'SHUFFLE',
  MOVE_PHOTO_CALENDAR = 'MOVE_PHOTO_CALENDAR',
  DELETE = 'DELETE', // Internal use only, delete button will not come from CMS
}

export default ButtonTypeEnum

import React from 'react'
import tw, { styled } from 'twin.macro'

export const SquareInner = tw.div`
  absolute
  inset-0
  overflow-hidden
`

export const SquareOuter = styled.div`
  position: relative;
  padding-bottom: 100%;
`

export const Square: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <SquareOuter {...props}>
      <SquareInner>{children}</SquareInner>
    </SquareOuter>
  )
}

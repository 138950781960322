import { flow, getRoot, Instance, types } from 'mobx-state-tree'

import { DEBUG, SOFTWARE_CODE, SOFTWARE_VERSION } from '../consts'
import { updateUserConfiguration } from '../api/products'
import { addToCart } from '../api/cart'
import { when } from 'mobx'

export const Cart = types
  .model({
    redirectUri: '',
    loading: false,
    error: '',
  })
  .views((self) => ({
    get hasError() {
      return !!self.error
    },
    get isLoading() {
      return self.loading
    },
  }))
  .actions((self) => ({
    setRedirectUri(redirectUri: string) {
      self.redirectUri = redirectUri
    },
    setLoading(loading: boolean) {
      self.loading = loading
    },
    setError(error: string) {
      self.error = error
    },
  }))
  .actions((self) => ({
    addToCart: flow(function* () {
      if (self.isLoading) return // avoid duplicated requests

      self.setLoading(true)
      self.setError('')

      const {
        session,
        userConfiguration,
        dynamicConfiguration: { userConfigurationsUrl },
      } = getRoot(self)

      yield userConfiguration.uploadSheets()
      yield userConfiguration.uploadImages() // TODO: improve upload management, avoid requesting upload several times just to be sure
      if (!session.isAuthenticated || !session.user?.isProfileCompleted) {
        self.setLoading(false)
        return
      }

      if (!userConfiguration.configurationId) {
        self.setLoading(false)
        self.setError('Configurazione mancante')
        return
      }

      yield when(() => userConfiguration.isUploadCompleted || userConfiguration.hasUploadError)

      if (userConfiguration.hasUploadError) {
        // Retry if upload had some errors
        self.setLoading(false)
        return
      }

      // Own userConfiguration
      yield updateUserConfiguration(
        userConfigurationsUrl,
        userConfiguration.configurationId,
        {},
        session.selectedLanguage,
        session.token
      )

      if (!self.redirectUri) {
        try {
          const cartRow = yield addToCart(
            userConfiguration.productType,
            userConfiguration.configurationId,
            session.token,
            userConfiguration.quantity
          )
          self.setRedirectUri(
            `${cartRow.redirectUri}&softwareCode=${SOFTWARE_CODE}&softwareVersion=${SOFTWARE_VERSION}&token=${session.token}`
          )
        } catch (exception) {
          self.setError("Errore nell'inserimento in carrello")
        }
      }

      self.setLoading(false)

      if (!DEBUG && !!self.redirectUri && !self.hasError) {
        window.location.href = self.redirectUri
      }
    }),
  }))

export interface ICart extends Instance<typeof Cart> {}

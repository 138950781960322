import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export const User = types
  .model({
    id: types.string,
    email: types.string,
    country: types.optional(types.string, ''),
    authorizeMarketingComunications: types.maybeNull(types.boolean),
    authorizeBehaviorProfiling: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get isProfileCompleted() {
      return !!self.country && self.authorizeMarketingComunications !== null && self.authorizeBehaviorProfiling !== null
    },
    get isStaff() {
      // Quick way to enable "staff only" features on some users.
      // Logic may - and most likely will - change.
      return self.email.indexOf('@photosi.com') > -1 || self.email.indexOf('@innoteam.it') > -1
    },
  }))

export interface IUser extends Instance<typeof User> {}
export interface IUserIn extends SnapshotIn<typeof User> {}
export interface IUserOut extends SnapshotOut<typeof User> {}

import { v4 as uuid4 } from 'uuid'
import { cast, flow, Instance, types } from 'mobx-state-tree'
import { removeAuthCookie, getAuthCookie, setAuthCookie, handleHttpError, fireEvent } from '../utils'
import i18n from 'i18next'
import * as Sentry from '@sentry/react'

import * as events from '../events'
import { getUserInfo } from '../api/auth'
import { User, IUserIn } from './User'

export const Session = types
  .model({
    id: types.optional(types.identifier, uuid4),
    token: types.string,
    selectedLanguage: types.string,
    user: types.maybe(User),
  })
  .actions((self) => ({
    afterCreate() {
      Sentry.setExtra('sessionId', self.id)
      console.log('CWC2021-1454', 'Session ID', self.id)
      Sentry.setUser({ username: self.id })
    },
    setUser(user: IUserIn) {
      self.user = cast(user)
    },
  }))
  .actions((self) => ({
    login: flow(function* (token: string) {
      self.token = token
      setAuthCookie(token)
      const userInfo = yield getUserInfo(token).catch(handleHttpError)
      if (!userInfo) return
      self.setUser(userInfo)
      fireEvent(events.AUTH_LOGIN, { userId: userInfo.id })
      Sentry.setUser({
        id: self.user?.id,
        email: self.user?.email,
        username: self.id,
      })
    }),
    logout() {
      removeAuthCookie()
      self.token = ''
      self.user = undefined
    },
    setSelectedLanguage(language: string) {
      i18n.changeLanguage(language)
      self.selectedLanguage = language
    },
  }))
  .actions((self) => ({
    autoLogin() {
      const token = getAuthCookie()
      if (!token) return
      self.login(token)
    },
  }))
  .views((self) => ({
    get isAuthenticated() {
      return !!self.user?.id
    },
  }))

export interface ISession extends Instance<typeof Session> {}

export const DEBUG = process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEBUG === 'true'
export const VERBOSE = process.env.REACT_APP_VERBOSE === 'true'
export const TRACK_TESTERS = process.env.REACT_APP_TRACK_TESTERS === 'true'
export const SOFTWARE_CODE = 'PHOTOSI_WEB_PRODUCT_SERVICES'
export const SOFTWARE_VERSION = '0.1.0'
export const AUTH_COOKIE = 'userToken'
export const LANGUAGE_COOKIE: string = 'phs_choosen_language'
export const GDO_COOKIE: string = 'GDOInfoData'
export const AVAILABLE_LANGUAGES: string[] = ['it', 'es']
export const DEFAULT_LANGUAGE = 'it'
export const EMAIL_REGEX = /.+@.+\..+/
export const GTM_ID = process.env.REACT_APP_GTM_ID as string
export const API_KEY = process.env.REACT_APP_API_KEY as string
export const BASE_API_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-api-key': API_KEY,
}
export const PRODUCTS_API_HEADERS = {
  ...BASE_API_HEADERS,
  'X-Software-Code': SOFTWARE_CODE,
  'X-Software-Version': SOFTWARE_VERSION,
}
export const CLOUD_FUNCTIONS_BASE_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL as string
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string
export const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL as string
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET as string
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string
export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY as string

export const MAX_RESOLUTION = process.env.REACT_APP_MAX_RESOLUTION || 6200

export const CLASSIC_PRINTS_ID = process.env.REACT_APP_PRINTS_CONFIGURATION_ID as string
export const VINTAGE_PRINTS_ID = process.env.REACT_APP_VINTAGE_CONFIGURATION_ID as string
export const HOMEDECOR_MDF_WOOD_ID = process.env.REACT_APP_HOMEDECOR_MDF_WOOD_CONFIGURATION_ID as string
export const HOMEDECOR_MDF_METAL_ID = process.env.REACT_APP_HOMEDECOR_MDF_METAL_CONFIGURATION_ID as string
export const HOMEDECOR_CANVAS_ID = process.env.REACT_APP_HOMEDECOR_CANVAS_CONFIGURATION_ID as string
export const HOMEDECOR_PIUMA_ID = process.env.REACT_APP_HOMEDECOR_PIUMA_CONFIGURATION_ID as string
export const CALENDAR_MONTHLY_ID = process.env.REACT_APP_CALENDAR_MONTHLY_CONFIGURATION_ID as string
export const CALENDAR_YEARLY_ID = process.env.REACT_APP_CALENDAR_YEARLY_CONFIGURATION_ID as string
export const CALENDAR_CARDS_ID = process.env.REACT_APP_CALENDAR_CARDS_CONFIGURATION_ID as string

const CLASSIC_PRINTS_PRODUCT_VIEW_ID = 'classic_prints'
const VINTAGE_PRINTS_PRODUCT_VIEW_ID = 'vintage_prints'
const HOMEDECOR_MDF_WOOD_PRODUCT_VIEW_ID = 'frame_wood'
const HOMEDECOR_MDF_METAL_PRODUCT_VIEW_ID = 'frame_metal'
const HOMEDECOR_CANVAS_PRODUCT_VIEW_ID = 'canvas'
const HOMEDECOR_LEGER_PRODUCT_VIEW_ID = 'leger'
const CALENDAR_MONTHLY_PRODUCT_VIEW_ID = 'monthly_calendars'
const CALENDAR_YEARLY_PRODUCT_VIEW_ID = 'yearly_calendars'
const CALENDAR_CARDS_PRODUCT_VIEW_ID = 'table_calendars'

export const AVAILABLE_PRODUCT_VIEW_IDS = [
  CLASSIC_PRINTS_PRODUCT_VIEW_ID,
  VINTAGE_PRINTS_PRODUCT_VIEW_ID,
  HOMEDECOR_MDF_WOOD_PRODUCT_VIEW_ID,
  HOMEDECOR_MDF_METAL_PRODUCT_VIEW_ID,
  HOMEDECOR_CANVAS_PRODUCT_VIEW_ID,
  HOMEDECOR_LEGER_PRODUCT_VIEW_ID,
  CALENDAR_MONTHLY_PRODUCT_VIEW_ID,
  CALENDAR_YEARLY_PRODUCT_VIEW_ID,
  CALENDAR_CARDS_PRODUCT_VIEW_ID,
]

export const FEATURED: {
  title: string
  dynamicLookups: [string, string[]][]
  configurations: {
    label: string
    configurationId: string
    productViewId: string
    lookups: [string, string][]
  }[]
}[] = [
  {
    title: 'Stampe',
    dynamicLookups: [],
    configurations: [
      {
        label: 'Classiche',
        configurationId: CLASSIC_PRINTS_ID,
        productViewId: CLASSIC_PRINTS_PRODUCT_VIEW_ID,
        lookups: [['category', 'SMALL_SIZE']],
      },
      {
        label: 'Vintage',
        configurationId: VINTAGE_PRINTS_ID,
        productViewId: VINTAGE_PRINTS_PRODUCT_VIEW_ID,
        lookups: [
          ['category', 'VINTAGE'],
          ['paper', 'MATTE'],
          ['boxType', 'NO'],
        ],
      },
    ],
  },
  {
    title: 'HomeDecor',
    dynamicLookups: [],
    configurations: [
      {
        label: 'MDF Wood',
        configurationId: HOMEDECOR_MDF_WOOD_ID,
        productViewId: HOMEDECOR_MDF_WOOD_PRODUCT_VIEW_ID,
        lookups: [
          ['collageLayout', '1x'],
          ['mosaicType', 'NO'],
          ['commercialProduct', 'FRAME_MDF'],
          ['frameMaterial', 'WOOD'],
          ['accessory', 'NO'],
          ['passepartout', 'YES'],
        ],
      },
      {
        label: 'MDF Metal',
        configurationId: HOMEDECOR_MDF_METAL_ID,
        productViewId: HOMEDECOR_MDF_METAL_PRODUCT_VIEW_ID,
        lookups: [
          ['collageLayout', '1x'],
          ['mosaicType', 'NO'],
          ['commercialProduct', 'FRAME_MDF'],
          ['frameMaterial', 'METAL'],
          ['accessory', 'NO'],
          ['passepartout', 'YES'],
        ],
      },
      {
        label: 'Canvas',
        configurationId: HOMEDECOR_CANVAS_ID,
        productViewId: CALENDAR_CARDS_PRODUCT_VIEW_ID,
        lookups: [
          ['collageLayout', '1x'],
          ['mosaicType', 'NO'],
          ['commercialProduct', 'CANVAS'],
          ['accessory', 'NO'],
        ],
      },
      {
        label: 'Piuma',
        configurationId: HOMEDECOR_PIUMA_ID,
        productViewId: HOMEDECOR_LEGER_PRODUCT_VIEW_ID,
        lookups: [
          ['collageLayout', '1x'],
          ['mosaicType', 'NO'],
          ['commercialProduct', 'LEGER'],
          ['accessory', 'NO'],
        ],
      },
    ],
  },
  {
    title: 'Calendari',
    dynamicLookups: [['calendarLanguage', AVAILABLE_LANGUAGES.map((s) => s.toUpperCase())]],
    configurations: [
      {
        label: 'Mensile',
        configurationId: CALENDAR_MONTHLY_ID,
        productViewId: CALENDAR_MONTHLY_PRODUCT_VIEW_ID,
        lookups: [
          ['category', 'MONTHLY'],
          ['supportType', 'SPIRAL'],
          ['brand', 'PHOTOSI'],
          ['packaging', 'DEFAULT'],
          ['year', '2022'],
        ],
      },
      {
        label: 'Mensile MrW',
        configurationId: CALENDAR_MONTHLY_ID,
        productViewId: CALENDAR_MONTHLY_PRODUCT_VIEW_ID,
        lookups: [
          ['category', 'MONTHLY'],
          ['supportType', 'SPIRAL'],
          ['brand', 'MR_WONDERFUL'],
          ['packaging', 'DEFAULT'],
          ['year', '2022'],
        ],
      },
      {
        label: 'Cards',
        configurationId: CALENDAR_CARDS_ID,
        productViewId: CALENDAR_CARDS_PRODUCT_VIEW_ID,
        lookups: [
          ['supportType', 'WOODENBASE'],
          ['brand', 'PHOTOSI'],
          ['packaging', 'DEFAULT'],
          ['year', '2022'],
          ['format', '12x17'],
        ],
      },
      {
        label: 'Cards MrW',
        configurationId: CALENDAR_CARDS_ID,
        productViewId: CALENDAR_CARDS_PRODUCT_VIEW_ID,
        lookups: [
          ['supportType', 'WOODENBASE'],
          ['brand', 'MR_WONDERFUL'],
          ['calendarLanguage', 'IT'],
          ['packaging', 'DEFAULT'],
          ['year', '2022'],
          ['format', '12x17'],
        ],
      },
      {
        label: 'Annuale',
        configurationId: CALENDAR_YEARLY_ID,
        productViewId: CALENDAR_YEARLY_PRODUCT_VIEW_ID,
        lookups: [
          ['category', 'YEARLY'],
          ['supportType', 'SPIRAL'],
          ['brand', 'PHOTOSI'],
          ['calendarLanguage', 'IT'],
          ['packaging', 'DEFAULT'],
          ['year', '2022'],
        ],
      },
    ],
  },
]

export const WEB_EDITOR_BASE_URL = process.env.REACT_APP_WEB_EDITOR_BASE_URL as string
export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL as string,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
  appId: process.env.REACT_APP_FIREBASE_APP_ID as string,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID as string,
}

export const FORM_BOOLEAN_TRUE: 'yes' = 'yes'
export const FORM_BOOLEAN_FALSE: 'no' = 'no'

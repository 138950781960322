import React, { useState } from 'react'
import 'twin.macro'
import { useForm } from 'react-hook-form'

import { updateProfile } from '../../api/auth'
import { useMst } from '../../models/RootStore'
import Form from '../../components/forms/Form'
import FormLabel from '../../components/forms/FormLabel'
import { PrimaryButton } from '../../components/Button'
import { SubTitle } from '../../components/Title'
import { useCountry } from '../../hooks/useCountry'
import { useTranslation } from 'react-i18next'
import { Radio, RadioGroup } from '../../components/forms/Radio'
import { FormError } from '../../components/forms/FormError'
import { boolToRadio, form_boolean, handleHttpError } from '../../utils'
import { FORM_BOOLEAN_FALSE, FORM_BOOLEAN_TRUE } from '../../consts'

type FormTypes = {
  communication: form_boolean
  profiling: form_boolean
}

export const CompleteProfileForm: React.FC = () => {
  const { t } = useTranslation('completeProfile')
  const { session, cart } = useMst()
  const [submitting, setSubmitting] = useState(false)
  const {
    register: registerField,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormTypes>({
    defaultValues: {
      communication: boolToRadio(session.user?.authorizeMarketingComunications),
      profiling: boolToRadio(session.user?.authorizeBehaviorProfiling),
    },
  })

  const communication = registerField('communication', { required: true })
  const profiling = registerField('profiling', { required: true })

  const [error, setError] = useState('')
  const { country } = useCountry()

  const loading = submitting || !country

  const formFilled = communication !== undefined && profiling !== undefined

  const onSubmit = (data: FormTypes) => {
    setSubmitting(true)
    setError('')
    setTimeout(async () => {
      const updatedProfile = await updateProfile(session.token, {
        country,
        authorizeMarketingComunications: data.communication === FORM_BOOLEAN_TRUE,
        authorizeBehaviorProfiling: data.profiling === FORM_BOOLEAN_TRUE,
      }).catch(handleHttpError)

      if (updatedProfile) {
        await session.login(session.token)
        cart.addToCart() // try adding to cart, if prerequisites are correct, TODO: emit a signal
      } else {
        setError(t('updateProfileError'))
      }
      setSubmitting(false)
    }, 1000)
  }

  const values = getValues()

  return (
    <div>
      <SubTitle tw="text-center">{t('completeProfile')}</SubTitle>
      <Form tw="mt-4" onSubmit={handleSubmit(onSubmit)} noValidate>
        {error && <FormError tw="text-center">{error}</FormError>}
        <div tw="w-full">
          <FormLabel tw="normal-case">{t('gdprCommunications')}</FormLabel>

          <RadioGroup>
            <Radio
              type="radio"
              {...communication}
              checked={values.communication === FORM_BOOLEAN_FALSE}
              onClick={() => {
                setValue('communication', FORM_BOOLEAN_FALSE, { shouldDirty: true, shouldValidate: true })
              }}
            >
              {t('no')}
            </Radio>
            <Radio
              type="radio"
              {...communication}
              checked={values.communication === FORM_BOOLEAN_TRUE}
              onClick={() => {
                setValue('communication', FORM_BOOLEAN_TRUE, { shouldDirty: true, shouldValidate: true })
              }}
            >
              {t('yes')}
            </Radio>
          </RadioGroup>
          {errors.communication && <FormError>{t('requiredError')}</FormError>}
          <FormLabel tw="normal-case">{t('gdprProfiling')}</FormLabel>
          <RadioGroup>
            <Radio
              type="radio"
              {...profiling}
              checked={values.profiling === FORM_BOOLEAN_FALSE}
              onClick={() => {
                setValue('profiling', FORM_BOOLEAN_FALSE, { shouldDirty: true, shouldValidate: true })
              }}
            >
              {t('no')}
            </Radio>
            <Radio
              type="radio"
              {...profiling}
              checked={values.profiling === FORM_BOOLEAN_TRUE}
              onClick={() => {
                setValue('profiling', FORM_BOOLEAN_TRUE, { shouldDirty: true, shouldValidate: true })
              }}
            >
              {t('yes')}
            </Radio>{' '}
          </RadioGroup>
          {errors.profiling && <FormError>{t('requiredError')}</FormError>}
        </div>
        <PrimaryButton type="submit" tw="w-full mt-16" disabled={loading || !formFilled}>
          {loading ? t('loading') : t('proceed')}
        </PrimaryButton>
      </Form>
    </div>
  )
}

import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'

import { AVAILABLE_LANGUAGES, DEBUG } from '../consts'
import { useMst } from '../models/RootStore'
import { setLanguage } from '../utils'

export const LanguageSelector: React.FC = observer(() => {
  const { t } = useTranslation('test')
  const {
    session: { selectedLanguage, setSelectedLanguage },
  } = useMst()

  const handleClick = (lang: string) => {
    setSelectedLanguage(lang)
    DEBUG && setLanguage(lang)
  }

  return (
    <div tw="mb-4">
      <p> Lingua (test translation: "{t('test')}")</p>
      <ul tw="flex space-x-4">
        {AVAILABLE_LANGUAGES.map((lang) => (
          <li key={lang}>
            <label>
              <input
                tw="mr-1"
                key={lang}
                type="radio"
                name="lang"
                checked={selectedLanguage === lang}
                onChange={() => handleClick(lang)}
              />
              {lang}
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
})

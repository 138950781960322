import React, { useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'

import { useMst } from '../../../models/RootStore'
import { IStep } from '../../../models/Step'
import { IImageIn } from '../../../models/Image'

import { Content } from '../../../components/layout/Content'

import BackgroundUrl from '../../../assets/img/BackgroundSoft.svg'
import UploadIcon from '../../../assets/img/icons/Upload.svg'
import SmartphoneIcon from '../../../assets/img/icons/Smartphone.svg'
import CameraIcon from '../../../assets/img/icons/Camera.svg'
import HeartEyes from '../../../assets/img/HeartEyes.svg'
import { useTranslation } from 'react-i18next'
import { DiscountScale } from '../../../components/DiscountScale'
import { Forward } from '../../../components/Forward'
import { Title } from '../../../components/Title'
import { VerticalContents } from '../../../components/VerticalContents'
import GooglePhotosIcon from '../../../assets/img/icons/GooglePhotos.svg'
import FacebookIcon from '../../../assets/img/icons/Facebook.svg'
import { usePageView } from '../../../hooks/usePageView'
import { fireEvent } from '../../../utils'
import * as events from '../../../events'
import { PaginationSelection } from '../../../components/PaginationSelection'
import { observer } from 'mobx-react-lite'
import { ImagePagination } from '../../../components/ImagePagination'

const Panel = tw.div`
  divide-y
  rounded-xl
  shadow-xl
  bg-white
  border
`
const PanelItem = tw.div`
  text-xl
  flex justify-center
  text-center
  space-x-4
  px-4
`
const Wrapper = tw.div`
  flex justify-center
`
const Img = tw.img`
  object-none object-center m-auto
`

const PanelIcon = (props: { src: string; alt?: string }) => {
  return (
    <Wrapper>
      <Img src={props.src} alt={props.alt} />
    </Wrapper>
  )
}

const PanelText = tw.div`
  py-4
`

const RoundIcon = tw.div`
  rounded-full flex justify-center items-center
`

const BlueBackground = styled.div`
  background-color: #e3fffe;
  border-radius: 50%/40px 40px 0 0;
  ${tw`p-12`}
`

type Props = {
  step: IStep
}

const ImageSelectionStep: React.FC<Props> = ({ step }) => {
  const { t } = useTranslation('imageSelectionStep')
  const { userConfiguration, dynamicConfiguration, productConfiguration } = useMst()
  const fromDeviceInput = useRef<HTMLInputElement>(null)
  const fromCameraInput = useRef<HTMLInputElement>(null)
  const [displayDiscountScale, setDisplayDiscountScale] = useState(false)
  const [images, setImages] = useState<IImageIn[]>([])
  const [useManualPagination, setUseManualPagination] = useState<boolean | undefined>(
    productConfiguration.allowManualPagination // forced for now, set to undefined to allow selection
  )

  const [minImages, maxImages] = productConfiguration.data
    ? [productConfiguration.data.minImages, productConfiguration.data.maxImages]
    : [1, 1]

  const paginationSelected = useManualPagination !== undefined
  const displayPaginationSelection =
    productConfiguration.allowManualPagination && !paginationSelected && images.length > 0
  const displayImagePagination = useManualPagination && images.length > 0

  const handleSelectionConfirm = (selectedImages: IImageIn[]) => {
    userConfiguration.setImages(selectedImages)
    step.next()
  }

  usePageView(() => {
    fireEvent(events.IMAGE_SELECTION_VIEW, {
      configurationId: dynamicConfiguration.id,
      step: step.id,
    })
  })

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    const files = Array.from(e.target.files)

    fireEvent(events.IMAGES_SELECTION_IMAGES_SELECTED, {
      configurationId: dynamicConfiguration.id,
      step: step.id,
      sourceType: 'local',
      numberOfPhotos: files.length,
    })

    const convertedImages: IImageIn[] = Array.from(files).map((file: File) => {
      return {
        fileType: productConfiguration.dataSheets[0].userImageFileType, // single page products for now
        url: URL.createObjectURL(file), // temporary solution while waiting for preview
        name: file.name,
        type: file.type,
        file,
      }
    })

    if (productConfiguration.allowManualPagination && (useManualPagination || !paginationSelected)) {
      setImages((currentImages) => [...currentImages, ...convertedImages])
    } else {
      handleSelectionConfirm(convertedImages)
    }

    e.target.value = '' // Reset input, allow selecting again the same images over and over again
  }

  const handleFromDeviceClick = () => {
    fireEvent(events.IMAGE_SELECTION_SOURCE_CLICK, {
      configurationId: dynamicConfiguration.id,
      step: step.id,
      sourceType: 'local',
    })
    fromDeviceInput.current?.click()
  }

  const handleFromCameraClick = () => {
    fromCameraInput.current?.click()
  }

  const handlePaginationSelection = (useManualPagination: boolean) => {
    setUseManualPagination(useManualPagination)
    if (!useManualPagination) {
      handleSelectionConfirm(images)
      step.next()
    }
  }

  return (
    <Content
      tw="flex flex-col justify-between"
      css={[`background-image: url(${BackgroundUrl}); background-size: 100% auto;`]}
    >
      <div tw="space-y-4 p-8">
        <p tw="text-center">
          {t('letsStart')} {dynamicConfiguration.displayDiscountScale && t('didYouKnow')}
        </p>
        {dynamicConfiguration.displayDiscountScale && (
          <div
            tw="flex p-4 bg-white border rounded-xl shadow-xl text-center space-x-4 justify-center items-center"
            onClick={() => setDisplayDiscountScale(true)}
          >
            <img tw="w-8 h-8" src={HeartEyes} alt="Smile with heart eyes" />
            <span tw="font-bold"> {t('printMorePayLess')}</span>
            <Forward handleForward={() => setDisplayDiscountScale(true)} />
          </div>
        )}
      </div>
      <BlueBackground onClick={handleFromDeviceClick}>
        <VerticalContents tw="space-y-4">
          <Title tw="text-xl">
            {productConfiguration.allowMultipleImages
              ? productConfiguration.allowMultipleProducts || !productConfiguration.data
                ? t('chooseYourPhotos')
                : t('chooseNPhotos', { n: productConfiguration.data?.maxImages })
              : t('chooseYourPhoto')}
          </Title>

          <RoundIcon tw="w-20 h-20">
            <img src={UploadIcon} alt="upload icon" />
          </RoundIcon>

          <Panel>
            <PanelItem>
              <PanelIcon src={SmartphoneIcon} alt="Smartphone" />
              <PanelText>{t('fromDevice')}</PanelText>
            </PanelItem>
            <input
              ref={fromDeviceInput}
              tw="hidden"
              type="file"
              accept="image/jpeg"
              multiple={productConfiguration.allowMultipleImages}
              disabled={!step.isActive}
              onChange={handleFileChange}
            />
            <PanelItem tw="hidden" onClick={handleFromCameraClick}>
              <PanelIcon src={CameraIcon} alt="Camera" />
              <PanelText>Scatta una foto</PanelText>
            </PanelItem>
            <input
              ref={fromCameraInput}
              tw="hidden"
              type="file"
              accept="image/jpeg"
              capture="environment"
              multiple={productConfiguration.allowMultipleImages}
              disabled={!step.isActive}
              onChange={handleFileChange}
            />
            <PanelItem tw="hidden">
              <PanelIcon src={FacebookIcon} alt="Facebook logo" />
              <PanelText>Da Facebook (soon)</PanelText>
            </PanelItem>
            <PanelItem tw="hidden">
              <PanelIcon src={GooglePhotosIcon} alt="Google logo" />
              <PanelText>Da Google Photos (soon)</PanelText>
            </PanelItem>
          </Panel>
        </VerticalContents>
      </BlueBackground>
      {displayDiscountScale && (
        <DiscountScale
          dismiss={() => {
            setDisplayDiscountScale(false)
          }}
        />
      )}
      {displayPaginationSelection && <PaginationSelection onSelection={handlePaginationSelection} />}
      {displayImagePagination && (
        <ImagePagination
          images={images}
          minImages={minImages}
          maxImages={maxImages}
          onAddImages={handleFromDeviceClick}
          onConfirm={handleSelectionConfirm}
        />
      )}
    </Content>
  )
}

export default observer(ImageSelectionStep)

import { useCallback, useEffect, useState } from 'react'
import { createUserConfiguration, updateUserConfiguration } from '../api/products'
import { useMst } from '../models/RootStore'
import { handleHttpError } from '../utils'

export const useUserConfiguration = () => {
  const [loading, setLoading] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [isError, setIsError] = useState(false)

  const {
    userConfiguration: {
      configurationId,
      setConfigurationId,
      productId,
      setProductType,
      setProductId,
      setPublicPrice,
      remoteQuantity,
      quantity,
      setRemoteQuantity,
    },
    dynamicConfiguration: { userConfigurationsUrl },
    productConfiguration,
    session: { selectedLanguage },
  } = useMst()

  const _updateUserConfiguration = useCallback(async () => {
    if (!productConfiguration.productId || !userConfigurationsUrl) {
      return
    }

    setFetching(true)
    setLoading(true)
    setIsError(false)

    if (productId === productConfiguration.productId && (quantity === 0 || remoteQuantity === quantity)) {
      // Not best product nor quantity changed, just skip
      // Ignore quantity = 0 since we cannot have it remotely, min is 1 on service
      setLoading(false)
      setFetching(false)
      return
    }

    if (!configurationId) {
      const userConfiguration = await createUserConfiguration(
        userConfigurationsUrl,
        { productId: productConfiguration.productId, quantity: 1 },
        selectedLanguage
      ).catch(handleHttpError)
      if (!userConfiguration) {
        setIsError(true)
        setLoading(false)
        setFetching(false)
        return
      }

      setProductType(userConfiguration.productType)
      setConfigurationId(userConfiguration.id)
      setProductId(userConfiguration.productId)
      setPublicPrice(userConfiguration.publicFormattedPrice)
      setRemoteQuantity(userConfiguration.quantity)
    } else {
      if (!quantity) {
        // Useless update until we get some quantity (which meand products have been initializated)
        setLoading(false)
        setFetching(false)
        return
      }
      const updatedUserConfiguration = await updateUserConfiguration(
        userConfigurationsUrl,
        configurationId,
        { productId: productConfiguration.productId, quantity: quantity },
        selectedLanguage
      ).catch(handleHttpError)

      setLoading(false)
      setFetching(false)

      if (!updatedUserConfiguration) {
        setIsError(true)
        return
      }

      setProductType(updatedUserConfiguration.productType)
      setConfigurationId(updatedUserConfiguration.id)
      setProductId(updatedUserConfiguration.productId)
      setPublicPrice(updatedUserConfiguration.publicFormattedPrice)
      setRemoteQuantity(updatedUserConfiguration.quantity)
    }
    setLoading(false)
    setFetching(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConfigurationsUrl, productConfiguration.productId, quantity])

  useEffect(() => {
    _updateUserConfiguration()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_updateUserConfiguration])

  return { isError, loading, retry: _updateUserConfiguration }
}

import { v4 as uuid4 } from 'uuid'
import { flow } from 'mobx'
import { Instance, SnapshotIn, SnapshotOut, getRoot, types } from 'mobx-state-tree'

import { createUserConfigurationFile, updateUserConfigurationFile } from '../api/products'
import ConfigurationFile, { IConfigurationFile } from './ConfigurationFile'
import { readFileAsync } from '../utils'

type UploadParams = {
  configurationFileType: string
  quantity: number
  name?: string
  orderIndex?: number
}

export const BaseFile = types
  .model({
    id: types.optional(types.identifier, uuid4),
    isUploading: false,
    isError: false,
    configurationFile: types.maybe(ConfigurationFile),
  })
  .actions((self) => ({
    setIsUploading(isUploading: boolean) {
      self.isUploading = isUploading
    },
    setIsError(isError: boolean) {
      self.isError = isError
    },
    setConfigurationFile(configurationFile: IConfigurationFile) {
      self.configurationFile = configurationFile
    },
    getFile: (): File => {
      throw new Error('getFile not implemented')
    },
  }))
  .actions((self) => ({
    fileUpload: flow(function* ({ configurationFileType, quantity, name, orderIndex }: UploadParams) {
      // Private upload flow implementation, prone to refactor
      const {
        userConfiguration: { configurationId },
        dynamicConfiguration: { configurationFilesUrl },
      } = getRoot(self)

      if (!configurationId || !configurationFilesUrl) {
        throw new Error('Trying to upload a file without prerequisites')
      }

      let file: File
      try {
        file = yield self.getFile()
      } catch (error) {
        console.error('Error getting file from editor')
        throw error
      }

      console.log('Uploading', name, file)

      if (!self.configurationFile) {
        const configurationFileInfo = yield createUserConfigurationFile(configurationFilesUrl, {
          configurationId: configurationId,
          filename: name || null,
          type: configurationFileType,
          id: self.id, // TODO: may need to be managed separately if id implementation changes
          orderIndex: orderIndex || 0,
          quantity: quantity,
        })
        console.log({ configurationFileInfo })
        self.setConfigurationFile(configurationFileInfo)
      }

      const uploadResp = yield fetch(self.configurationFile?.uploadUrl as string, {
        method: 'PUT',
        body: yield readFileAsync(file),
        headers: {
          'Content-Type': file.type,
        },
      })
      console.log({ uploadResp })
      const updatedConfigurationFile = yield updateUserConfigurationFile(
        configurationFilesUrl,
        self.configurationFile?.id as string,
        {
          uploaded: true,
        }
      )
      self.setConfigurationFile(updatedConfigurationFile)
      console.log({ updatedConfigurationFile })
    }),
  }))
  .actions((self) => ({
    upload: flow(function* ({ configurationFileType, quantity, name, orderIndex }: UploadParams) {
      if (self.isUploading) {
        console.warn('Already uploading', self.id)
        return
      }
      if (self.configurationFile?.uploaded) {
        console.warn('Already uploaded', self.id)
        return
      }
      self.setIsUploading(true)
      self.setIsError(false)
      yield self.fileUpload({ configurationFileType, name, quantity, orderIndex }).catch((error) => {
        console.error('Error uploading', error)
        self.setIsError(true)
      })
      self.setIsUploading(false)
    }),
  }))

export interface IFile extends Instance<typeof BaseFile> {}
export interface IFileIn extends SnapshotIn<typeof BaseFile> {}
export interface IFileOut extends SnapshotOut<typeof BaseFile> {}

import React, { useState } from 'react'
import 'twin.macro'
import { useForm } from 'react-hook-form'

import { authenticate } from '../../api/auth'
import { useMst } from '../../models/RootStore'
import Form from '../../components/forms/Form'
import FormInput from '../../components/forms/FormInput'
import FormLabel from '../../components/forms/FormLabel'
import { PrimaryButton } from '../../components/Button'
import { Link } from '../../components/Link'
import { EMAIL_REGEX } from '../../consts'
import { useTranslation } from 'react-i18next'
import { ResetPassword } from './ResetPassword'
import { Close } from '../../components/Close'
import { SubTitle } from '../../components/Title'
import { FormError } from '../../components/forms/FormError'

type Props = {
  reset: () => void
}

export const LoginForm: React.FC<Props> = ({ reset }) => {
  const { t } = useTranslation('login')
  const { session, cart } = useMst()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [loading, setLoading] = useState(false)
  const email = register('email', {
    required: true,
    pattern: EMAIL_REGEX,
  })
  const password = register('password', {
    required: true,
  })
  const [error, setError] = useState('')
  const [displayResetPassword, setDisplayResetPassword] = useState(false)

  const formFilled = !!email && !!password

  const onSubmit = (data) => {
    setLoading(true)
    setError('')
    setTimeout(async () => {
      const accessToken = await authenticate(data.email, data.password).catch((e) => {
        console.error(e)
        setError(t('invalidCredentials'))
      })
      if (accessToken) {
        await session.login(accessToken)
        reset()
        cart.addToCart() // try adding to cart, if prerequisites are correct, TODO: emit a signal
      }
      setLoading(false)
    }, 1000)
  }

  if (displayResetPassword) {
    return <ResetPassword reset={() => setDisplayResetPassword(false)} />
  }

  return (
    <div>
      <div tw="grid grid-cols-title mb-4">
        <Close tw="place-self-start" onClick={reset} />
        <SubTitle tw="text-center">{t('loginWithEmail')}</SubTitle>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        {error && <FormError tw="text-center">{error}</FormError>}
        <FormLabel>{t('email')}</FormLabel>
        <FormInput {...email} placeholder={t('emailPlaceholder')} disabled={loading} />
        {errors.email && <FormError>{t('emailError')}</FormError>}
        <FormLabel>{t('password')}</FormLabel>
        <FormInput type="password" {...password} placeholder={t('passwordPlaceholder')} disabled={loading} />
        {errors.password && <FormError>{t('passwordError')}</FormError>}
        <p tw="w-full text-left  text-xs">
          <Link type="button" tw="text-gray-400" onClick={() => setDisplayResetPassword(true)}>
            {t('forgotPassword')}
          </Link>
        </p>
        <PrimaryButton type="submit" tw="w-full mt-16" disabled={loading || !formFilled}>
          {loading ? t('loading') : t('login')}
        </PrimaryButton>
      </Form>
    </div>
  )
}

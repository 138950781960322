import amplitude from 'amplitude-js'
import { useEffect, useState } from 'react'

import { AMPLITUDE_API_KEY, AVAILABLE_PRODUCT_VIEW_IDS } from '../consts'
import * as events from '../events'
import { useMst } from '../models/RootStore'
import { listenEvent } from '../utils'

const setUp = (client: amplitude.AmplitudeClient, productViewId: string | null) => {
  // Auth
  listenEvent(events.AUTH_LOGIN, ({ detail: { userId } }) => {
    client.setUserId(userId)
  })

  // Images
  listenEvent(events.IMAGE_SELECTION_VIEW, ({ detail: { configurationId, step } }) => {
    client.logEvent('dynamic_picker_view', {
      productview_ID: productViewId,
      dynamicconfiguration_ID: configurationId,
      stepsequence: step,
    })
  })

  listenEvent(events.IMAGE_SELECTION_SOURCE_CLICK, ({ detail: { configurationId, step, sourceType } }) => {
    client.logEvent('dynamic_picker_source_click', {
      productview_ID: productViewId,
      dynamicconfiguration_ID: configurationId,
      stepsequence: step,
      sourceType,
    })
  })

  listenEvent(
    events.IMAGES_SELECTION_IMAGES_SELECTED,
    ({ detail: { configurationId, step, sourceType, numberOfPhotos } }) => {
      client.logEvent('dynamic_picker_images_selected', {
        productview_ID: productViewId,
        dynamicconfiguration_ID: configurationId,
        stepsequence: step,
        numberOfPhotos,
      })
    }
  )

  // Lookups
  listenEvent(events.LOOKUP_VIEW, ({ detail: { configurationId, step, stepCode } }) => {
    client.logEvent('lookup_view', {
      productview_ID: productViewId,
      dynamicconfiguration_ID: configurationId,
      stepsequence: step,
      stepCode: stepCode,
    })
  })

  listenEvent(events.LOOKUP_SELECT, ({ detail: { configurationId, step, stepCode, numberOfChoices, lookUpId } }) => {
    client.logEvent('lookup_select', {
      productview_ID: productViewId,
      dynamicconfiguration_ID: configurationId,
      stepsequence: step,
      stepCode: stepCode,
      number_of_choices: numberOfChoices,
      lookUpId,
    })
  })

  // Personalize
  listenEvent(events.PERSONALIZE_START, () => {
    client.logEvent('personalize_start', {
      productview_ID: productViewId,
    })
  })

  listenEvent(events.ADD_TO_CART, () => {
    client.logEvent('cart_add', {
      productview_ID: productViewId,
    })
  })

  listenEvent(events.PERSONALIZE_ADD_PHOTOS, () => {
    client.logEvent('personalize_add_photo', {
      productview_ID: productViewId,
    })
  })

  listenEvent(events.PERSONALIZE_REPLACE_IMAGE, () => {
    client.logEvent('personalize_replace_image', {
      productview_ID: productViewId,
    })
  })

  listenEvent(events.PERSONALIZE_EDIT_IMAGE, () => {
    client.logEvent('personalize_edit_image', {
      productview_ID: productViewId,
    })
  })

  listenEvent(events.PERSONALIZE_EDIT_CHANGE_FORMAT, () => {
    client.logEvent('personalize_change_format', {
      productview_ID: productViewId,
    })
  })

  listenEvent(events.PERSONALIZE_DELETE_PHOTO, () => {
    client.logEvent('personalize_delete_photo', {
      productview_ID: productViewId,
    })
  })

  // Edit
  listenEvent(events.MODIFY_PHOTO_VIEW, () => {
    client.logEvent('modify_photo_view', {
      productview_ID: productViewId,
    })
  })

  listenEvent(events.MODIFY_PHOTO_SELECT_FILTER, () => {
    client.logEvent('modify_photo_select_filter', {
      productview_ID: productViewId,
    })
  })

  listenEvent(events.MODIFY_PHOTO_SELECT_TRANSFORM, () => {
    client.logEvent('modify_photo_select_transform', {
      productview_ID: productViewId,
    })
  })

  listenEvent(events.MODIFY_PHOTO_FILTER_SAVE, () => {
    client.logEvent('modify_photo_filter_save', {
      productview_ID: productViewId,
    })
  })
}

const tearDown = () => {}

export const useAmplitude = () => {
  const {
    userConfiguration: { productViewId },
  } = useMst()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (productViewId === undefined) return
    if (initialized) return

    const client = amplitude.getInstance()
    client.init(
      AMPLITUDE_API_KEY,
      undefined,
      {
        saveEvents: true,
        includeUtm: true,
        includeReferrer: true,
      },
      (client: amplitude.AmplitudeClient) => {
        client.setDomain(window.location.hostname)
      }
    )
    const identify = new amplitude.Identify().set('platform_type', 'Mobile')
    client.identify(identify)
    setUp(client, productViewId && AVAILABLE_PRODUCT_VIEW_IDS.includes(productViewId) ? productViewId : null)
    setInitialized(true)
    return () => {
      tearDown()
    }
  }, [productViewId])
}

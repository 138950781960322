import React from 'react'
import tw from 'twin.macro'
import SwipableViews from 'react-swipeable-views'

type PaginationProps = {
  count: number
  current: number
  onSelect: (index: number) => void
}

const Pagination: React.FC<PaginationProps> = ({ count, current, onSelect }) => {
  const positions = Array.from(Array(count).keys())
  const handleItemClick = (index: number) => () => onSelect(index)

  return (
    <div tw="flex justify-center space-x-2">
      {positions.map((i, index) => (
        <span
          key={index}
          tw="w-3 h-3 rounded-full bg-gray-400"
          css={[i === current ? tw`bg-gray-400` : tw`bg-gray-300`]}
          onClick={handleItemClick(index)}
        ></span>
      ))}
    </div>
  )
}

type Props = {
  current: number
  onChange: (index: number) => void
}
export const Carousel: React.FC<Props> = ({ current, onChange, children }) => {
  const itemsCount = React.Children.count(children)

  return (
    <div tw="h-full flex flex-col justify-center">
      <SwipableViews index={current} tw="pb-4 px-4" slideStyle={{ overflow: 'unset' }} onChangeIndex={onChange}>
        {children}
      </SwipableViews>
      <Pagination count={itemsCount} current={current} onSelect={onChange} />
    </div>
  )
}

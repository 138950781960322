import React from 'react'
import tw from 'twin.macro'

import { BackDrop } from './BackDrop'
import { Close } from './Close'

type ModalProps = {
  dismiss?: () => void
}
const SpacedBackDrop = tw(BackDrop)`
  p-4
`

const BaseModal = tw.div`
  w-full max-h-full
  p-6
  rounded border-gray-200
  bg-white border
  overflow-scroll
  max-h-full
`

const Dismiss = tw(Close)`
  absolute top-0 right-0
  m-8
`

export const Modal: React.FC<ModalProps> = ({ dismiss, children }) => {
  return (
    <SpacedBackDrop>
      <BaseModal tw="place-self-start">{children}</BaseModal>
      {dismiss && <Dismiss onClick={dismiss}>&times;</Dismiss>}
    </SpacedBackDrop>
  )
}

export const FullModal: React.FC<ModalProps> = ({ dismiss, children }) => {
  return (
    <SpacedBackDrop>
      <BaseModal tw="flex-grow">{children}</BaseModal>
      {dismiss && <Dismiss onClick={dismiss}>&times;</Dismiss>}
    </SpacedBackDrop>
  )
}

export const CenterModal: React.FC<ModalProps> = ({ dismiss, children }) => {
  return (
    <SpacedBackDrop>
      <BaseModal tw="place-self-center">{children}</BaseModal>
      {dismiss && <Dismiss onClick={dismiss}>&times;</Dismiss>}
    </SpacedBackDrop>
  )
}

export const ModalTitle = tw.h3`
  px-4
  text-2xl
  font-black
`

export const ModalText = tw.p`
  px-4
  text-center
`

import { observer } from 'mobx-react-lite'
import React from 'react'
import 'twin.macro'

import { useMst } from '../models/RootStore'

type Value = string | number | boolean | undefined

type OptionValueProps = {
  name: string
  current: Value
  label: string
  value: Value
  changeHandler: (value: Value) => void
}

const OptionValue: React.FC<OptionValueProps> = ({ name, current, label, value, changeHandler }) => {
  return (
    <li>
      <label>
        <input tw="mr-1" type="radio" name={name} checked={current === value} onChange={() => changeHandler(value)} />
        {label}
      </label>
    </li>
  )
}

type OptionProps = {
  title: string
  name: string
  changeHandler: (value: any) => void
  current: Value
  values: {
    label: string
    value: Value
  }[]
}

const Option: React.FC<OptionProps> = ({ title, name, current, changeHandler, values }) => {
  return (
    <div tw="mb-4">
      <p>{title}</p>
      <ul tw="flex space-x-4">
        {values.map(({ value, label }) => (
          <OptionValue
            key={value?.toString()}
            name={name}
            current={current}
            changeHandler={changeHandler}
            label={label}
            value={value}
          />
        ))}
      </ul>
    </div>
  )
}

export const OptionsSelector: React.FC = observer(() => {
  const {
    options: {
      autoPreview,
      setAutoPreview,
      preloadOriginalImages,
      setPreloadOriginalImages,
      prioritizeFirstPreview,
      setPrioritizeFirstPreview,
    },
  } = useMst()

  return (
    <>
      <Option
        title="Generazione automatica delle anteprime"
        name="autoPreview"
        changeHandler={setAutoPreview}
        current={autoPreview}
        values={[
          { label: 'No', value: false },
          { label: 'Sì', value: true },
        ]}
      />
      <Option
        title="Usa immagini originali in attesa dell'anteprima"
        name="preloadOriginalImages"
        changeHandler={setPreloadOriginalImages}
        current={preloadOriginalImages}
        values={[
          { label: 'No', value: false },
          { label: 'Sì', value: true },
        ]}
      />
      <Option
        title="Genera la prima anteprima con priorità più alta"
        name="prioritizeFirstPreview"
        changeHandler={setPrioritizeFirstPreview}
        current={prioritizeFirstPreview}
        values={[
          { label: 'No', value: false },
          { label: 'Sì', value: true },
        ]}
      />
    </>
  )
})

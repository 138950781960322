import React from 'react'
import 'twin.macro'

export type LookupItemProps = {
  name: string
  description?: string | null
  image?: string
  price?: string
}

const SQUARE_ASPECT_RATIO = 100
const RECTANGULAR_ASPECT_RATIO = (18 / 33) * 100

export const LookupCard: React.FC<LookupItemProps & { aspectRatio?: number }> = ({
  name,
  description,
  image,
  price,
  aspectRatio = RECTANGULAR_ASPECT_RATIO,
}) => {
  return (
    <div tw="rounded-lg shadow-md my-4 mx-2 overflow-hidden">
      <div
        tw="w-full bg-cover bg-center"
        css={[
          `
          padding-bottom: ${aspectRatio}%;
          background-image: url(${image});
          `,
        ]}
      ></div>
      <div tw="flex-none px-4 py-2 border-t">
        <div tw="flex">
          <h3 tw="flex-grow text-sm font-semibold">{name}</h3>
          {price && <div tw="text-sm flex-none">{price}</div>}
        </div>
        <p tw="text-xs text-gray-600">{description || <br />}</p>
      </div>
    </div>
  )
}

export const SquareLookupCard: React.FC<LookupItemProps> = (props) => (
  <LookupCard {...props} aspectRatio={SQUARE_ASPECT_RATIO} />
)

export const LookupListItem: React.FC<LookupItemProps> = ({ name, image }) => {
  return (
    <div tw="rounded shadow-md my-2 mx-4 p-4 overflow-hidden flex items-center space-x-4 font-semibold">
      <div tw="w-16 h-16 bg-contain bg-center" css={[`background-image: url(${image})`]}></div>
      <h3 tw="flex-grow">{name}</h3>
      <span tw="text-gray-400 text-2xl">›</span>
    </div>
  )
}

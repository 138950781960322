import { API_BASE_URL, PRODUCTS_API_HEADERS } from '../consts'
import { getDistributor } from '../utils'

export const addToCart = async function (
  productType: string,
  userConfigurationId: string,
  userToken: string,
  quantity: number = 1
) {
  const distributor = getDistributor()
  const response = await fetch(`${API_BASE_URL}/iplabs-shopping-cart/rows/?distributor=${distributor}`, {
    method: 'POST',
    headers: {
      ...PRODUCTS_API_HEADERS,
      Authorization: `Bearer ${userToken}`,
    },
    body: JSON.stringify({
      productType: productType,
      userConfigurationId: userConfigurationId,
      quantity: quantity,
      transactionId: null,
    }),
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response)
}

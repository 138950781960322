import React from 'react'
import Lottie from 'react-lottie'

type Props = {
  data: { [key: string]: any } // TODO: can we better infer what is in the json ?
}

export const Animation: React.FC<Props> = ({ data }) => {
  const options = {
    animationData: data,
  }

  return <Lottie options={options} />
}

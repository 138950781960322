import tw from 'twin.macro'

export const Footer = tw.div`
  flex-none
  w-full
  p-4
  border-t
  bg-white
  shadow
`

import { useEffect } from 'react'
import { useMst } from '../models/RootStore'

export const useLogin = () => {
  const {
    session: { autoLogin },
  } = useMst()
  useEffect(() => {
    autoLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

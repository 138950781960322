import { CLOUD_FUNCTIONS_BASE_URL } from '../consts'
import { db } from '../firebase'

export async function getDynamicPersonalization(productId: string, language: string) {
  const response = await fetch(`${CLOUD_FUNCTIONS_BASE_URL}/getCachedDynamicPersonalization?productId=${productId}`, {
    headers: {
      'Accept-Language': language,
    },
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response)
}

export async function getDynamicConfiguration(configurationId: string, language: string) {
  const response = await db
    .collection('app-consumer-catalogs')
    .doc('data')
    .collection('dynamicConfigurations')
    .doc('translatedData')
    .collection(language)
    .doc(configurationId)
    .get()

  const data = response.data()
  if (!data) {
    return Promise.reject(data)
  }
  return data
}

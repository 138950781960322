import tw from 'twin.macro'

const Button = tw.button`
  flex
  justify-center items-center
  w-6 h-6
  bg-gray-300
  text-white text-lg
  font-semibold
  rounded-full
  focus:(outline-none)
`

export const Close: React.FC<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = (props) => <Button {...props}>&times;</Button>

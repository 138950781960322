import React from 'react'
import tw from 'twin.macro'
import useLookup from '../hooks/useLookup'

import { IButton } from '../models/Button'
import { useMst } from '../models/RootStore'
import { BottomSheet } from './BottomSheet'
import { GenericError } from './Error'
import { Loader } from './Loader'
import { Title } from './Title'

type Props = IButton & {
  selectLookup: (code: string, value: string) => void
  dismiss: () => void
}

export const LookupSelector: React.FC<Props> = ({ code, title, lookupData, lookupUrl, selectLookup, dismiss }) => {
  const { productConfiguration } = useMst()
  const currentValue = productConfiguration.data ? productConfiguration.data[code] : undefined

  const params = new URLSearchParams(productConfiguration.data?.querystringFilters)
  params.delete(code)
  const { choices, loading, isError, getChoices } = useLookup(code, lookupUrl, params.toString())

  const filteredLookupData = lookupData.filter((ld) => choices.find((c) => c.id === ld.id))

  if (isError) {
    return <GenericError retryHandler={getChoices} />
  }

  return (
    <BottomSheet tall dismiss={dismiss}>
      <Title>{title}</Title>
      {loading ? (
        <Loader />
      ) : (
        <div tw="mt-4 grid grid-cols-3 gap-4">
          {filteredLookupData.map((option) => (
            <div
              tw="flex flex-col items-center"
              onClick={() => {
                selectLookup(code, option.id)
                dismiss()
              }}
            >
              <div tw="h-20 w-20 mb-2">
                {option.images[0] ? (
                  <img tw="object-contain" src={option.images[0].url} alt={option.name} />
                ) : (
                  <div tw="w-full h-full border" />
                )}
              </div>
              <p css={[currentValue === option.id && tw`text-orange-400`]}>{option.name}</p>
              {currentValue === option.id && <p tw="text-orange-400">⦁</p>}
            </div>
          ))}
        </div>
      )}
    </BottomSheet>
  )
}

import * as crypto from 'crypto'

import { IUserOut } from '../models/User'

import {
  AUTH_BASE_URL,
  API_BASE_URL,
  BASE_API_HEADERS,
  CLIENT_ID,
  CLIENT_SECRET,
  PRODUCTS_API_HEADERS,
} from '../consts'

type LoginResponse = {
  access_token: string
  expires_in: number
  token_type: string
  refresh_token: string
}

function generateSalt() {
  return crypto.randomBytes(8).toString('hex')
}

function hashPassword(password: string): string {
  const iterations = 12000
  const salt = generateSalt()
  const hashed = crypto.pbkdf2Sync(password, salt, iterations, 32, 'sha256') // TODO: use async version
  return ['pbkdf2_sha256', 12000, salt, hashed.toString('base64')].join('$')
}

export const authenticate = async function (email: string, password: string): Promise<string> {
  const response = await fetch(`${AUTH_BASE_URL}/oauth2/token?client_id=${CLIENT_ID}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + btoa(`${CLIENT_ID}:${CLIENT_SECRET}`),
    },
    body: `username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&grant_type=password`,
  })
  if (response.ok) {
    const tokenData = (await response.json()) as LoginResponse
    return tokenData.access_token
  }
  return Promise.reject(response)
}

type RegistrationBody = {
  email: string
  password: string
  country: string
  selectedLanguage: string
  authorizeMarketingComunications: boolean
  authorizeBehaviorProfiling: boolean
}
export const register = async function ({
  email,
  password,
  country,
  selectedLanguage,
  authorizeMarketingComunications,
  authorizeBehaviorProfiling,
}: RegistrationBody): Promise<string> {
  const hashedPassword = hashPassword(password)
  const response = await fetch(`${API_BASE_URL}/accounts/consumer-user-profile?siteCode=miofotografo`, {
    method: 'POST',
    headers: {
      ...BASE_API_HEADERS,
    },
    body: JSON.stringify({
      email: email,
      hashedPassword,
      country,
      authorizeMarketingComunications,
      authorizeBehaviorProfiling,
      selectedLanguage,
    }),
  })
  if (response.ok) {
    return authenticate(email, password)
  }
  const errorResponse = await response.json()
  return Promise.reject({
    type: errorResponse.type,
    // WTF? currently a typo is returned from server, check both wrong and corrent string
    alreadyUsed: ['EMAIL_AREADY_REGISTERED', 'EMAIL_ALREADY_REGISTERED'].includes(errorResponse.type),
  })
}

type CompleteProfileBody = {
  country: string
  authorizeMarketingComunications: boolean
  authorizeBehaviorProfiling: boolean
}
export const updateProfile = async function (
  token: string,
  { country, authorizeMarketingComunications, authorizeBehaviorProfiling }: CompleteProfileBody
): Promise<IUserOut> {
  const response = await fetch(`${API_BASE_URL}/accounts/consumer-user-profile/current`, {
    method: 'PUT',
    headers: {
      ...BASE_API_HEADERS,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      country,
      authorizeMarketingComunications,
      authorizeBehaviorProfiling,
    }),
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject()
}

export const resetPassword = async function (email: string, language: string): Promise<void> {
  const response = await fetch(`${API_BASE_URL}/accounts/consumer-user-profile/send-reset-password-email`, {
    method: 'POST',
    headers: {
      ...PRODUCTS_API_HEADERS,
      'Accept-Language': language,
    },
    body: JSON.stringify({ email }),
  })
  if (response.ok) {
    return Promise.resolve()
  }
  return Promise.reject()
}

type UserInfoResponse = {
  id: string
  email: string
  authorizeMarketingComunication: boolean | null
  authorizeBehaviorProfiling: boolean | null
  country: string | null
}
export async function getUserInfo(token: string): Promise<UserInfoResponse> {
  const response = await fetch(`${API_BASE_URL}/accounts/consumer-user-profile/current`, {
    method: 'GET',
    headers: {
      ...BASE_API_HEADERS,
      Authorization: `Bearer ${token}`,
    },
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject()
}

import React, { useState } from 'react'
import 'twin.macro'

import { FEATURED } from '../consts'
import { SubTitle } from './Title'

const localUrl = `${window.location.protocol}//${window.location.host}`
const envs = [
  { label: 'Local', baseUrl: localUrl },
  { label: 'Test', baseUrl: 'https://consumer-configurator-js.cloud.photoforse.online' },
  { label: 'Live', baseUrl: 'https://editor.photosi.com' },
]

function featuredToUrl(baseUrl: string, configurationId: string, lookups: [string, string][]) {
  const url = new URL(baseUrl)
  url.searchParams.append('configurationId', configurationId)
  lookups.forEach(([code, value]) => {
    url.searchParams.append(code, value)
  })
  return url.href
}

type DynamicLookupProps = {
  code: string
  value?: string
  values: string[]
  onChange: (code: string, value: string) => void
}
const DynamicLookups: React.FC<DynamicLookupProps> = ({ code, value, values, onChange }) => {
  const handleChange = (code: string, value: string) => (e: React.FormEvent<HTMLInputElement>) => onChange(code, value)

  return (
    <div tw="text-right text-sm space-x-2">
      <span>{code}:</span>
      {values.map((v) => (
        <label key={v}>
          <input type="radio" onChange={handleChange(code, v)} name={code} checked={value === v} /> {v}
        </label>
      ))}
    </div>
  )
}

const LinkGroup: React.FC<{ featured: typeof FEATURED[0] }> = ({ featured }) => {
  const { title, configurations, dynamicLookups } = featured
  const dynamicLookupDefaults = dynamicLookups.reduce((defaults, [code, values]) => {
    defaults[code] = values[0]
    return defaults
  }, {})
  const [options, setOptions] = useState<{ [key: string]: string }>(dynamicLookupDefaults)
  const optionsList: [string, string][] = []
  for (let code in options) {
    optionsList.push([code, options[code]])
  }

  const handleOptionClick = (code: string, value: string) => {
    setOptions((state) => {
      return {
        ...state,
        [code]: value,
      }
    })
  }

  return (
    <div tw="mb-4 w-full">
      <SubTitle>{title}</SubTitle>
      {dynamicLookups.map(([code, values]) => (
        <DynamicLookups key={code} code={code} value={options[code]} values={values} onChange={handleOptionClick} />
      ))}
      {configurations.map(({ label, configurationId, lookups }, index) => (
        <div tw="flex" key={index}>
          <div tw="flex-grow">{label}</div>
          <div tw="space-x-2">
            {envs.map(({ label, baseUrl }) => (
              <a
                key={label}
                tw="hover:text-teal-400"
                href={featuredToUrl(baseUrl, configurationId, [...lookups, ...optionsList])}
              >
                {label}
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export const TestLinks: React.FC = () => {
  return (
    <>
      {FEATURED.map((featured, index) => (
        <LinkGroup key={index} featured={featured} />
      ))}
    </>
  )
}

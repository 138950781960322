import { useCallback, useEffect, useState } from 'react'
import { getLookups, LookupResponse } from '../api/products'
import { useMst } from '../models/RootStore'
import { handleHttpError } from '../utils'

function useLookup(code: string, url?: string, lookupFilters?: { [key: string]: string } | string) {
  const [loading, setLoading] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [choices, setChoices] = useState<LookupResponse[]>([])
  const [prices, setPrices] = useState<{ [key: string]: string }>({})
  const [isError, setIsError] = useState<boolean>(false)
  const {
    userConfiguration,
    availableProducts,
    session: { selectedLanguage },
  } = useMst()

  const calculatePrices = (choices) => {
    return choices.reduce((prices, choice) => {
      const matchingProducts = availableProducts.filter((p) => p[code] === choice.id)
      if (matchingProducts.length > 0) {
        const bestProduct = matchingProducts.reduce((old, curr) => {
          return old?.publicUnitPrice < curr.publicUnitPrice ? old : curr
        })
        if (bestProduct) {
          prices[choice.id] = bestProduct.publicUnitFormattedPrice
        }
      }
      return prices
    }, {})
  }

  const getChoices = useCallback(async () => {
    if (!url) return // cannot go any further
    if (fetching) return // avoid multiple calls
    setFetching(true)
    setLoading(true)

    const availableChoices = await getLookups(
      url,
      lookupFilters || userConfiguration.lookupFilters,
      selectedLanguage
    ).catch(handleHttpError)
    if (!availableChoices || availableChoices.length < 1) {
      setIsError(true)
      setLoading(false)
      setFetching(false)
      return
    }
    setChoices(availableChoices)
    // Todo: inglobe prices with choices
    const prices = calculatePrices(availableChoices)
    setPrices(prices)
    setLoading(false)
    setFetching(false)
  }, [url, code, JSON.stringify(userConfiguration.lookupFilters), selectedLanguage])

  useEffect(() => {
    getChoices()
  }, [getChoices])

  return { choices, prices, loading, isError, getChoices }
}

export default useLookup

import { observer } from 'mobx-react-lite'
import React from 'react'
import tw from 'twin.macro'
import { DEBUG } from '../../consts'

import { useMst } from '../../models/RootStore'

const Button = tw.div`
  fixed top-0 right-0
  p-1 m-1 rounded
  cursor-pointer
  text-xs text-blue-400
  bg-blue-100
  border border-blue-200
`

export const Profile: React.FC = observer(() => {
  const { session } = useMst()

  if (!session.isAuthenticated || !DEBUG) return null

  return <Button onClick={() => session.logout()}>Logout</Button>
})

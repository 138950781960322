import React from 'react'
import tw, { styled } from 'twin.macro'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import BackgroundUrl from '../assets/img/BackgroundHard.svg'
import { VerticalContents } from './VerticalContents'
import { Title as DefaultTitle } from './Title'
import { PrimaryButton } from './Button'

const Fixed = tw.div`
  fixed
  inset-0
  bg-white
`

const Content = styled(tw(VerticalContents)`
  h-full
  m-3 px-8 py-12
  text-center
  space-y-40
`)`
  background-image: url(${BackgroundUrl});
  background-size: 100% auto;
`

const TextSection = tw(VerticalContents)`
  flex-grow
  items-center
  justify-center
`
const Title = tw(DefaultTitle)`
  px-6
  text-2xl
`
const Description = tw.p`
  text-lg
`
const ButtonsSection = tw(VerticalContents)`
  w-full
  px-8 space-y-4
`

const SelectionButton = tw(PrimaryButton)`
  w-full
  py-3
  rounded-xl
`

type Props = {
  onSelection: (useManualPagination: boolean) => void
}

export const PaginationSelection: React.FC<Props> = observer(({ onSelection }) => {
  const { t } = useTranslation('imageSelectionStep')

  const handleImagePaginationSelection = (useManualPagination: boolean) => () => {
    onSelection(useManualPagination)
  }

  return (
    <Fixed>
      <Content>
        <TextSection>
          <Title>{t('howToPaginateCalendar')}</Title>
          <Description>{t('youCanChoosePagination')}</Description>
        </TextSection>
        <ButtonsSection>
          <SelectionButton onClick={handleImagePaginationSelection(false)}>{t('automatically')}</SelectionButton>
          <SelectionButton onClick={handleImagePaginationSelection(true)}>{t('manually')}</SelectionButton>
        </ButtonsSection>
      </Content>
    </Fixed>
  )
})

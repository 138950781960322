import React from 'react'
import tw from 'twin.macro'

import { BackDrop } from './BackDrop'
import { Close } from './Close'

const Content = tw.div`
  fixed bottom-0 w-full
  max-h-full overflow-y-scroll
  z-10
  p-4
  border
  rounded-t-2xl
  bg-white
`

const Dismiss = tw(Close)`
  absolute right-0 top-0
  m-4
`

type Props = {
  tall?: boolean
  dismiss?: () => void
}

export const BottomSheet: React.FC<Props> = ({ children, dismiss, tall }) => {
  return (
    <BackDrop>
      <Content css={[tall && tw`h-4/5`]}>
        {dismiss && <Dismiss onClick={dismiss}>&times;</Dismiss>}
        {children}
      </Content>
    </BackDrop>
  )
}

export const BottomSheetTitle = tw.h3`
  text-2xl
  font-black
`

import tw from 'twin.macro'

const HiddenInput = tw.input`
  opacity-0 w-0 h-0
`

const CheckboxLabel = tw.label`
  flex
  items-start
  text-gray-400
`

const CheckboxSquare: React.FC<{ checked?: boolean }> = ({ checked }) => {
  return (
    <span
      css={[
        tw`
          flex justify-center items-center
          w-5 h-5
          mr-2
          border border-teal-400
          rounded
        `,
        checked && tw`bg-teal-400`,
      ]}
    >
      {checked && <CheckboxCheck />}
    </span>
  )
}

const CheckboxCheck = tw.span`
  w-3 h-2
  border-white border-l-2 border-b-2
  transform rotate--45
  mb-1
`

export const Checkbox = (
  props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
) => {
  const { children, ...inputProps } = props
  return (
    <CheckboxLabel>
      <CheckboxSquare checked={props.checked} />
      {children}
      <HiddenInput type="checkbox" {...inputProps} />
    </CheckboxLabel>
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

import { IImageIn } from '../models/Image'
import { Thumbnail } from '../components/Thumbnail'
import { SubTitle } from './Title'
import { PrimaryButton } from './Button'
import { BackDrop } from './BackDrop'
import { Square } from './Square'

type Props = {
  images: IImageIn[]
  minImages: number
  maxImages: number
  onAddImages: () => void
  onConfirm: (images: IImageIn[]) => void
}

export const ImagePagination: React.FC<Props> = ({ images, minImages, maxImages, onAddImages, onConfirm }) => {
  const { t } = useTranslation('imageSelectionStep')
  const [selected, setSelected] = useState<typeof images>([])

  const imagesToAdd = Math.max(minImages - images.length, 0)
  const imagesToSelect = Math.max(minImages - selected.length, 0)

  const handleConfirmClick = () => onConfirm(selected)

  const handleImageClick = (img: IImageIn) => () => {
    if (selected.includes(img)) {
      setSelected((selected) => selected.filter((s) => s !== img))
    } else if (selected.length < maxImages) {
      setSelected((selected) => [...selected, img])
    }
  }

  return (
    <BackDrop>
      <Drawer>
        <Header>
          <SubTitle>{t('yourPhotos')}</SubTitle>
        </Header>
        <Gallery>
          {images.map((i, index) => {
            const position = selected.includes(i) ? selected.indexOf(i) + 1 : undefined
            return (
              <ImageContainer key={index} onClick={handleImageClick(i)}>
                <Image file={i.file} alt={i.name} />
                {position && (
                  <SelectedOverlay>
                    <Position>{position}</Position>
                  </SelectedOverlay>
                )}
              </ImageContainer>
            )
          })}
        </Gallery>
        {imagesToAdd > 0 ? (
          <Button onClick={onAddImages}>
            {imagesToAdd === 1 ? t('addOneMoreImage') : t('addMoreImages', { n: imagesToAdd })}
          </Button>
        ) : (
          <Button disabled={imagesToSelect > 0} onClick={handleConfirmClick}>
            {imagesToSelect === 1
              ? t('selectOneMoreImage')
              : imagesToSelect > 1
              ? t('selectMoreImages', { n: imagesToSelect })
              : t('confirm')}
          </Button>
        )}
      </Drawer>
    </BackDrop>
  )
}

const Drawer = tw.div`
  fixed inset-0
  flex flex-col
  mt-4 p-4 space-y-4
  border-t
  rounded-t-2xl
  bg-white
`

const Header = tw.div`
  w-full
  text-center
`

const RoundIcon = tw.span`
  rounded-full
  text-white
  flex justify-center items-center
`

const Position = tw(RoundIcon)`
  absolute
  h-4 w-4
  bottom-1 right-1
  bg-teal-400
  text-xs
`

const Gallery = tw.div`
  flex-grow
  content-start
  grid grid-cols-3
  gap-4
  overflow-y-scroll
`

const ImageContainer = tw(Square)`
  w-full
  bg-gray-100
`

const SelectedOverlay = tw.div`
  absolute
  inset-0
  border-2 border-teal-400
`

const Image = tw(Thumbnail)`
  object-cover w-full h-full
`

const Button = tw(PrimaryButton)`
  w-full
`

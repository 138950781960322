import React from 'react'
import 'twin.macro'

import ArrowRight from '../assets/img/icons/ArrowRight.svg'

type Props = {
  handleForward: () => void
}

export const Forward: React.FC<Props> = ({ handleForward }) => {
  return (
    <span tw="inline-block align-baseline text-blue-600 justify-center w-6">
      <a onClick={handleForward}>
        <img src={ArrowRight} alt="Forward" />
      </a>
    </span>
  )
}

import tw from 'twin.macro'

const HiddenInput = tw.input`
  opacity-0 w-0 h-0
`

export const RadioGroup = tw.div`
  flex
  space-x-8
  mb-4
  pt-2
`

const RadioLabel = tw.label`
  flex
  items-start
  text-gray-400
`

const RadioCircle: React.FC<{ checked?: boolean }> = ({ checked }) => {
  return (
    <span
      css={[
        tw`
          flex justify-center items-center
          w-6 h-6
          mr-2
          border border-teal-400
          rounded-full
        `,
        checked && tw`bg-teal-400`,
      ]}
    ></span>
  )
}

const RadioCheck = tw.span`
  w-3 h-2
  border-white border-l-4 border-b-4
  transform rotate--45
  mb-1
`

export const Radio = (
  props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
) => {
  const { children, ...inputProps } = props
  return (
    <RadioLabel>
      <RadioCircle checked={props.checked} />
      {children}
      <HiddenInput type="radio" {...inputProps} />
    </RadioLabel>
  )
}

import React, { useState } from 'react'
import { CenterModal } from './Modal'
import { VerticalContents } from './VerticalContents'
import HeartEyes from '../assets/img/HeartEyes.svg'
import { SubTitle } from './Title'
import { useTranslation } from 'react-i18next'
import tw, { styled } from 'twin.macro'
import { PrimaryButton } from './Button'

const Table = tw.div`
  w-full
`

const Row = tw.div`
  flex
  justify-between
  w-full
  py-2 px-4
  mb-1
  bg-gray-100
  rounded-xl
`

const FakeRow = tw.div`
  h-10
  mb-1
`

const Heading = tw(Row)`
  divide-x divide-gray-300
  py-1 px-1
  mb-3
`

const Tier = styled.span<{ selected?: boolean }>(({ selected }) => [
  tw`
    text-center
    flex-grow
    px-1
  `,
  selected && tw`bg-white rounded-md shadow-xl`,
])

const Discount = tw.span`
  p-1
  rounded-md
  bg-orange-300
  text-orange-600
  text-xs
  font-semibold
`

const Price = tw.span`
  font-semibold
`

// Version A from Zeplin
const discounts = {
  '10x': [
    {
      photos: 10,
      price: '0,24',
    },
    {
      photos: 100,
      discount: 21,
      price: '0,19',
    },
    {
      photos: 200,
      discount: 42,
      price: '0,14',
    },
    {
      photos: 400,
      discount: 50,
      price: '0,12',
    },
  ],
  '12x': [
    {
      photos: 10,
      price: '0,29',
    },
    {
      photos: 100,
      discount: 17,
      price: '0,24',
    },
    {
      photos: 200,
      discount: 34,
      price: '0,19',
    },
    null,
  ],
  '13x': [
    {
      photos: 10,
      price: '0,34',
    },
    {
      photos: 100,
      discount: 15,
      price: '0,29',
    },
    {
      photos: 200,
      discount: 29,
      price: '0,24',
    },
    {
      photos: 400,
      discount: 38,
      price: '0,21',
    },
  ],
}

type DiscountRowProps = {
  photos: number
  discount: number
  price: string
}
const DiscountRow: React.FC<DiscountRowProps> = ({ photos, discount, price }) => {
  const { t } = useTranslation('discountScale')

  return (
    <Row key={photos}>
      <span>
        {photos}+ {t('photos')}
      </span>
      <span tw="space-x-2">
        {discount && <Discount>-{discount}%</Discount>}
        <Price>{price} €</Price>
      </span>
    </Row>
  )
}

type Props = {
  dismiss: () => void
}

export const DiscountScale: React.FC<Props> = ({ dismiss }) => {
  const { t } = useTranslation('discountScale')
  const [selectedFormat, setFormat] = useState('10x')

  return (
    <CenterModal dismiss={dismiss}>
      <VerticalContents>
        <img src={HeartEyes} alt="Smile with heart eyes" />
        <SubTitle>{t('printMorePayLess')}</SubTitle>
        <Table>
          <Heading>
            {Object.keys(discounts).map((format) => (
              <Tier key={format} selected={format === selectedFormat} onClick={() => setFormat(format)}>
                {format}
              </Tier>
            ))}
          </Heading>
          {discounts[selectedFormat].map((discount) => (discount ? <DiscountRow {...discount} /> : <FakeRow />))}
        </Table>
        <PrimaryButton tw="w-full" onClick={dismiss}>
          {t('continue')}
        </PrimaryButton>
      </VerticalContents>
    </CenterModal>
  )
}

import React from 'react'

import 'twin.macro'
import ArrowLeft from '../assets/img/icons/ArrowLeft.svg'

type Props = {
  handleBack: () => void
}

export const Back: React.FC<Props> = ({ handleBack }) => {
  return (
    <span tw="inline-block align-baseline text-blue-600 justify-center w-6">
      <a onClick={handleBack}>
        <img src={ArrowLeft} alt="Back" />
      </a>
    </span>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { getProducts, getDataSheets, getSvg } from '../api/products'
import { useMst } from '../models/RootStore'
import { handleHttpError } from '../utils'

export const useProductConfigurations = () => {
  const [loading, setLoading] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [isError, setIsError] = useState(false)

  const {
    userConfiguration,
    dynamicConfiguration: { userConfigurationsUrl, productConfigurationsUrl },
    productConfiguration,
    setProductConfiguration,
    setAvailableProducts,
    session: { selectedLanguage },
  } = useMst()

  const serializedLookups = JSON.stringify(userConfiguration.lookupFilters)

  const _updateProductConfigurations = useCallback(async () => {
    if (!userConfigurationsUrl || !productConfigurationsUrl) {
      return
    }
    if (fetching) return // avoid multiple calls
    setFetching(true)
    setLoading(true)
    setIsError(false)

    const lookupFilters =
      userConfiguration.personalizationReady && !!productConfiguration.data
        ? productConfiguration.lookupFilters
        : userConfiguration.lookupFilters

    if (userConfiguration.personalizationReady) {
      Object.entries(userConfiguration.lookupFilters).forEach(([k, v]) => {
        if (lookupFilters[k] !== undefined) {
          lookupFilters[k] = v
        }
      })
    }

    console.log('filters', JSON.stringify(JSON.stringify))
    const availableProducts = await getProducts(productConfigurationsUrl, lookupFilters, selectedLanguage).catch(
      handleHttpError
    )

    if (!availableProducts || availableProducts.length === 0) {
      console.warn('No matching products')
      setIsError(true)
      setLoading(false)
      setFetching(false)
      return
    }
    const [bestProduct] = availableProducts

    setAvailableProducts(availableProducts)

    const bestProductId = bestProduct.id
    if (bestProductId === productConfiguration.productId) {
      // Not best product nor quantity changed, just skip
      setLoading(false)
      setFetching(false)
      return
    }
    // New product is the best: get datasheets/svg data
    const datasheets = await getDataSheets(bestProduct.datasheets).catch(handleHttpError)
    if (!datasheets) {
      setIsError(true)
      setLoading(false)
      setFetching(false)
      return
    }

    const dataSheetsWithSvgData = await Promise.all(
      datasheets.map(async (ds) => {
        const svg = await getSvg(ds.svgProduction)

        const { outputWidth, outputHeight } = svg
        return {
          ...ds,
          outputWidth,
          outputHeight,
        }
      })
    ).catch(handleHttpError)
    if (!dataSheetsWithSvgData) {
      setIsError(true)
      setLoading(false)
      setFetching(false)
      return
    }
    setProductConfiguration({
      productId: bestProductId,
      dataSheets: dataSheetsWithSvgData,
      data: bestProduct,
    })

    setLoading(false)
    setFetching(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productConfigurationsUrl, serializedLookups])

  useEffect(() => {
    _updateProductConfigurations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_updateProductConfigurations])

  return { isError, loading, retry: _updateProductConfigurations }
}

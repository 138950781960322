import React from 'react'
import 'twin.macro'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/react'

import { RootStoreProvider, rootStore } from './models/RootStore'
import PageSelector from './features/PageSelector'
// import Notifications from './features/Notifications'
import { Authentication } from './features/auth/Authentication'
import { Profile } from './features/auth/Profile'
import { GenericError } from './components/Error'
import { TrackingBanner } from './components/TrackingBanner'
import { TRACK_TESTERS } from './consts'

function App() {
  return (
    <div tw="container m-auto">
      <RootStoreProvider value={rootStore}>
        <ErrorBoundary
          FallbackComponent={() => <GenericError />}
          onError={(error, trace) => {
            console.error('Sentry log', error, trace)
            // Temp, more info before release, rootStore is too large for this
            Sentry.setContext('rootStore', window.rootStore.toJSON())
            Sentry.captureException(error)
          }}
        >
          <PageSelector />
          <Authentication />
          <Profile />
          {/* <Notifications /> */}
          {TRACK_TESTERS && <TrackingBanner trackingId={rootStore.session.id} />}
        </ErrorBoundary>
      </RootStoreProvider>
    </div>
  )
}

export default App

import React from 'react'
import 'twin.macro'

type Props = {
  title?: string
  progress: number
  current?: number
  total?: number
}

const ProgressBar: React.FC<Props> = ({ title, progress, current, total }) => {
  const percent = Math.round(progress * 100)

  const styles = {
    width: `${percent}%`,
  }

  return (
    <div tw="relative flex w-full shadow shadow-inner border border-teal-400 rounded bg-gray-200">
      <div tw="h-4 bg-teal-200 overflow-hidden" style={styles}></div>
      <span tw="absolute w-full text-xs text-center text-teal-600">
        {title} {percent}% {}
      </span>
    </div>
  )
}

export default ProgressBar

import tw from 'twin.macro'

export const ToggleButton = tw.div`
  rounded bg-gray-200 p-1 flex space-x-1
`

export const ToggleItem: React.FC<{
  active?: boolean
}> = ({ active, children }) => {
  return (
    <div tw="py-1 px-2 rounded" css={[active && tw`bg-white`]}>
      {children}
    </div>
  )
}

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Preview } from 'react-dnd-preview'
import tw from 'twin.macro'

import { useDraggable, useDroppable } from '../hooks/useDnd'
import { BottomSheet } from './BottomSheet'
import { DndZone } from './DragAndDrop'
import { SubTitle } from './Title'
import { ISheet } from '../models/Sheet'
import { observer } from 'mobx-react-lite'

const Img = tw.img`
  h-24 w-24
  object-cover
`
const DropOverlay = tw.div`
  absolute inset-0 opacity-30 bg-teal-200
`

const ImgContainer: React.FC<{
  id?: string
  onDrop: (item: { id: string }) => void
}> = ({ id, onDrop, children }) => {
  const { ref, isOver } = useDroppable(onDrop)

  return (
    <div tw="relative h-24 w-24 bg-gray-100" ref={ref}>
      {id && isOver && <DropOverlay />}
      {children}
    </div>
  )
}

const DraggableImg: React.FC<{
  id: string
  url: string
  label: string
}> = ({ url, id, label }) => {
  const { ref, opacity } = useDraggable({ id, url })

  return <Img ref={ref} src={url} alt={label} style={{ opacity }} />
}

const Title = tw(SubTitle)`
  text-center
  mb-2
`

const Grid = tw.div`
  grid
  grid-cols-3
  gap-x-2
  gap-y-1
`

const Cell = tw.div`
  flex flex-col justify-between items-center
`
const Label = tw.div`
  text-sm
`

const generatePreview = ({ item, style }) => {
  return <Img src={item.url} alt="preview" style={style} />
}

type Props = {
  sheets: ISheet[]
  onSwap: (from: string, to: string) => void
  onDismiss: () => void
}

export const ImageSwapper: React.FC<Props> = observer(({ sheets, onSwap, onDismiss }) => {
  const { t } = useTranslation('swap')

  useEffect(() => {
    // Avoid body scroll while drag/dropping
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const months = [
    t('january'),
    t('february'),
    t('march'),
    t('april'),
    t('may'),
    t('june'),
    t('july'),
    t('august'),
    t('september'),
    t('october'),
    t('november'),
    t('december'),
  ]

  const sheetThatCouldHouseImages: ISheet[] = sheets.filter((s) =>
    s.editor ? s.editor.svgFeatures.userImagesCount > 0 : false
  )
  const swappables: {
    sheetId: string
    imageUrl?: string
    imageId?: string
    label: string
  }[] = sheetThatCouldHouseImages.map((sheet, i) => {
    // Assume one image per sheet
    const base = {
      label: months[i],
      sheetId: sheet.id,
    }
    const image = sheet.images[0]
    if (image) {
      return { ...base, imageUrl: image.previewUrl, imageId: image.id }
    }
    return base
  })

  const handleDrop = (from?: string) => (item: { id: string }) => {
    !!from && onSwap(from, item.id)
  }

  return (
    <BottomSheet dismiss={onDismiss}>
      <Title>{t('swap')}</Title>
      <DndZone>
        <Grid>
          {swappables.map(({ sheetId, imageId, imageUrl, label }, index) => (
            <Cell key={sheetId + imageId}>
              <ImgContainer id={sheetId} onDrop={handleDrop(sheetId)}>
                {imageUrl && sheetId && <DraggableImg id={sheetId} url={imageUrl} label={label} />}
              </ImgContainer>
              <Label>{label}</Label>
            </Cell>
          ))}
        </Grid>
        <Preview>{generatePreview}</Preview>
      </DndZone>
    </BottomSheet>
  )
})

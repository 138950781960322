import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

import { PrimaryButton } from '../../../components/Button'
import { Carousel } from '../../../components/Carousel'

const Container = tw.div`
  flex flex-col
  h-full
`
const Content = tw.div`
  flex-grow
`
const Footer = tw.div`
  p-4 pb-8
  border-t border-gray-100
`
const ConfirmButton = tw(PrimaryButton)`
  w-full
  py-3
`

type Props = {
  onSelect: (index: number) => () => void
}

export const PagerTemplate: React.FC<Props> = ({ onSelect, children }) => {
  const { t } = useTranslation('lookupStep')
  const [current, setCurrent] = useState(0)

  return (
    <Container>
      <Content>
        <Carousel current={current} onChange={setCurrent}>
          {children}
        </Carousel>
      </Content>
      <Footer>
        <ConfirmButton onClick={onSelect(current)}>{t('proceed')}</ConfirmButton>
      </Footer>
    </Container>
  )
}

import { useCallback, useEffect, useState } from 'react'

import { getCountry } from '../api/geoip'
import { useMst } from '../models/RootStore'
import { getCountryFromLanguage, handleHttpError } from '../utils'

export const useCountry = () => {
  const {
    session: { selectedLanguage, user },
  } = useMst()
  const [country, setCountry] = useState<string>(user?.country || '')
  const [loading, setLoading] = useState(false)

  const _getCountry = useCallback(async () => {
    if (loading) return
    setLoading(true)
    const currentCountry = country || (await getCountry().catch(handleHttpError))
    setCountry(currentCountry || getCountryFromLanguage(selectedLanguage))
    setLoading(false)
  }, [])

  useEffect(() => {
    _getCountry()
  }, [_getCountry])

  return { country }
}

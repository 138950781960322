import React, { useEffect, useState } from 'react'
import { generatePreviewUrl } from '../utils'

type Props = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  file: File
}

export const Thumbnail: React.FC<Props> = ({ file, alt, ...props }) => {
  const [previewUrl, setPreviewUrl] = useState<string | undefined>()

  useEffect(() => {
    generatePreviewUrl(file).then((url) => setPreviewUrl(url))
  }, [file])

  return previewUrl ? <img src={previewUrl} alt={alt} {...props} /> : null
}

import React from 'react'
import { observer } from 'mobx-react-lite'
import 'twin.macro'

import { useMst } from '../../models/RootStore'
import { Page } from '../../components/layout/Page'

import DynamicStep from './DynamicStep'
import { GenericError } from '../../components/Error'

const Configurator: React.FC = () => {
  const {
    dynamicConfiguration: { currentStep },
  } = useMst()

  return <Page>{!currentStep ? <GenericError /> : <DynamicStep step={currentStep} />}</Page>
}

export default observer(Configurator)

import { getRoot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import i18next from 'i18next'

import ProductTypeEnum from './ProductType'

interface IBaseProductConfiguration {
  id: string
  productType: ProductTypeEnum
  brand: string
  brandName: string
  category: string
  currencyCode: string
  datasheets: string[]
  formattedPrice: string
  maxConfigurableProducts: number
  maxImages: number
  minConfigurableProducts: number
  minImages: number
  // previews: [],
  price: number
  publicUnitFormattedPrice: string
  publicUnitPrice: number
  querystringFilters: string
}

export interface IPrintProduct extends IBaseProductConfiguration {
  productType: ProductTypeEnum.PRINT_PRODUCT

  band: string
  bandName: string
  boxCategory: string
  boxCategoryName: string
  boxStyle: string
  boxStyleName: string
  boxType: string
  boxTypeName: string
  correction: string
  correctionName: string
  format: string
  formatName: string
  frontDatasheet: string | null
  hasMultipleItems: boolean
  orientation: string
  orientationName: string
  paper: string
  paperName: string
  passepartout: string
  passepartoutName: string
  photoText: string
  photoTextName: string
  productLanguage: string
  quality: string
  qualityName: string
  style: string
  styleName: string
}

export interface IHomeDecor extends IBaseProductConfiguration {
  productType: ProductTypeEnum.HOME_DECOR

  accessory: string
  accessoryName: string
  backDatasheet: string | null
  code: string
  collageLayout: string
  collageLayoutName: string
  commercialProduct: string
  commercialProductName: string
  correction: string
  correctionName: string
  format: string
  formatName: string
  frameColor: string
  frameColorName: string
  frameMaterial: string
  frameMaterialName: string
  frameThickness: string
  frameThicknessName: string
  frameType: string
  frameTypeName: string
  frontDatasheet: string | null
  kitType: string
  kitTypeName: string
  mosaicType: string
  mosaicTypeName: string
  orientation: string
  orientationName: string
  packaging: string
  packagingName: string
  paper: string
  paperLamination: string
  paperLaminationName: string
  paperName: string
  passepartout: string
  passepartoutColor: string
  passepartoutColorName: string
  passepartoutName: string
  photoMaterial: string
  photoMaterialName: string
  photoSupport: string
  photoSupportName: string
  productLanguage: string
  sideColor: string
  sideColorName: string
  sideThickness: string
  sideThicknessName: string
  spacer: string
  spacerName: string
  style: string
  styleName: string
  supportColor: string
  supportColorName: string
  supportMaterial: string
  supportMaterialName: string
  supportType: string
  supportTypeName: string
}

export interface ICalendar extends IBaseProductConfiguration {
  productType: ProductTypeEnum.CALENDAR

  calendarLanguage: string
  calendarLanguageName: string
  categoryName: string
  frontDatasheet1: string
  frontDatasheet2: string
  frontDatasheet3: string
  frontDatasheet4: string
  frontDatasheet5: string
  frontDatasheet6: string
  frontDatasheet7: string
  frontDatasheet8: string
  frontDatasheet9: string
  frontDatasheet10: string
  frontDatasheet11: string
  frontDatasheet12: string
  frontDatasheetCover: string
  kit: string
  kitName: string
  orientation: string
  orientationName: string
  packaging: string
  packagingName: string
  paper: string
  paperName: string
  style: string
  styleName: string
  supportType: string
  supportTypeName: string
  year: string
  yearName: string
}

export const DataSheet = types.model({
  id: types.string,
  readyToPrintFileType: types.string,
  svgFileType: types.string,
  svgPreview: types.string,
  svgPreviewUrl: types.string,
  svgProduction: types.string,
  svgProductionUrl: types.string,
  userImageFileType: types.string,
  outputWidth: types.number,
  outputHeight: types.number,
})

export const ProductConfiguration = types
  .model({
    productId: types.string,
    productType: types.maybe(types.string),
    dataSheets: types.array(DataSheet),
    data: types.maybe(types.frozen<IFrozenProductConfiguration>()),
  })
  .views((self) => ({
    get isUnique() {
      const { availableProducts } = getRoot(self)
      return availableProducts.length === 1 && availableProducts[0].id === self.productId
    },
    get enablePersonalizeLookups() {
      // Allow lookups only for products that can can host only a single configuration
      return !self.data || self.data.maxImages * self.data.maxConfigurableProducts === 1
    },
    get allowMultipleImages() {
      return !!self.data && self.data.maxImages * self.data.maxConfigurableProducts > 1
    },
    get allowMultipleProducts() {
      return !!self.data && self.data.maxConfigurableProducts > 1
    },
    get requiredImages() {
      return !!self.data ? self.data.minImages : 0
    },
    get isMultiPage() {
      return self.dataSheets.length > 1
    },
    get canRotateSvg() {
      return (
        !!self.data && self.data.productType === ProductTypeEnum.PRINT_PRODUCT && self.data.category === 'SMALL_SIZE'
      )
    },
    get allowManualPagination() {
      return self.data ? self.data.maxImages > 1 : false
    },
    get lookupFilters() {
      const filters: { [key: string]: string } = {}
      const params = new URLSearchParams(self.data?.querystringFilters)

      params.forEach((value, code) => {
        filters[code] = value
      })
      return filters
    },
    get backgroundColors() {
      if (!self.data) return []

      switch (self.data.productType) {
        case ProductTypeEnum.CALENDAR:
          switch (self.data.style) {
            case 'PALETTE':
              return [
                { code: '#C5DFD9', label: i18next.t('colors:palette1') },
                { code: '#CCCCD0', label: i18next.t('colors:palette2') },
                { code: '#F3C9B1', label: i18next.t('colors:palette3') },
                { code: '#E5E5CA', label: i18next.t('colors:palette4') },
                { code: '#C6DAE5', label: i18next.t('colors:palette5') },
                { code: '#C7E5E5', label: i18next.t('colors:palette6') },
                { code: '#D8E4EE', label: i18next.t('colors:palette7') },
                { code: '#FAEBAA', label: i18next.t('colors:palette8') },
                { code: '#E6C9C7', label: i18next.t('colors:palette9') },
                { code: '#FAD2CF', label: i18next.t('colors:palette10') },
              ]
            default:
              // 'RAINBOW' is used as default, just like in app
              return [
                { code: '#689ACB', label: i18next.t('colors:rainbow1') },
                { code: '#F3989B', label: i18next.t('colors:rainbow2') },
                { code: '#6BC3C1', label: i18next.t('colors:rainbow3') },
                { code: '#DEB8D8', label: i18next.t('colors:rainbow4') },
                { code: '#98C39A', label: i18next.t('colors:rainbow5') },
                { code: '#98CCEC', label: i18next.t('colors:rainbow6') },
                { code: '#F5DA63', label: i18next.t('colors:rainbow7') },
                { code: '#9DD4D7', label: i18next.t('colors:rainbow8') },
                { code: '#FCC970', label: i18next.t('colors:rainbow9') },
                { code: '#AE80B8', label: i18next.t('colors:rainbow10') },
                { code: '#F9CBCD', label: i18next.t('colors:rainbow11') },
                { code: '#EC6661', label: i18next.t('colors:rainbow12') },
              ]
          }
        /*
        case 'BOOK': // future proof, not used for now
          return [
            { code: '#FFFFFF', label: i18next.t('colors:white') },
            { code: '#FFC5C5', label: i18next.t('colors:pink') },
            { code: '#FFA599', label: i18next.t('colors:salmon') },
            { code: '#F9D9BA', label: i18next.t('colors:skin') },
            { code: '#88B6FF', label: i18next.t('colors:lightBlue') },
            { code: '#ACD6B5', label: i18next.t('colors:sage') },
            { code: '#F2F283', label: i18next.t('colors:lemon') },
            { code: '#000000', label: i18next.t('colors:black') },
          ]
          */
        default:
          return [
            { code: '#FFFFFF', label: i18next.t('colors:white') },
            { code: '#95D0E8', label: i18next.t('colors:lightBlue') },
            { code: '#3D64CD', label: i18next.t('colors:blue') },
            { code: '#6DC1AE', label: i18next.t('colors:mint') },
            { code: '#FCE665', label: i18next.t('colors:yellow') },
            { code: '#FFA599', label: i18next.t('colors:salmon') },
            { code: '#F46060', label: i18next.t('colors:red') },
            { code: '#F1C1D2', label: i18next.t('colors:pink') },
            { code: '#F96EA7', label: i18next.t('colors:fuchsia') },
            { code: '#000000', label: i18next.t('colors:black') },
          ]
      }
    },
    get textColors() {
      if (!self.data) return []

      // Should be used by vintage prints only
      return [
        { code: '#000000', label: i18next.t('colors:black') },
        { code: '#FFFFFF', label: i18next.t('colors:white') },
        { code: '#ED3131', label: i18next.t('colors:red') },
        { code: '#34AE6F', label: i18next.t('colors:green') },
        { code: '#3D64CD', label: i18next.t('colors:blue') },
        { code: '#ABA63D', label: i18next.t('colors:militaryGreen') },
      ]
    },
  }))

// Naming is a bit ambiguous for now, will be reworked soon, ideally bringing data up a level
export type IFrozenProductConfiguration = IPrintProduct | IHomeDecor | ICalendar
export interface IProductConfiguration extends Instance<typeof ProductConfiguration> {}
export interface IProductConfigurationIn extends SnapshotIn<typeof ProductConfiguration> {}
export interface IProductConfigurationOut extends SnapshotOut<typeof ProductConfiguration> {}
export interface IDataSheet extends Instance<typeof DataSheet> {}
export interface IDataSheetIn extends SnapshotIn<typeof DataSheet> {}
export interface IDataSheetOut extends SnapshotOut<typeof DataSheet> {}

import { when } from 'mobx'
import { cast, flow, getRoot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

import { PhotosiEditor } from '../editor'
import { Editor } from './Editor'
import Image, { IImage } from './Image'
import { BaseFile, IFile } from './File'
import { getRootStore } from './RootStore'
import { DataSheet, IDataSheet } from './ProductConfiguration'

export const Sheet = types.compose(
  BaseFile,
  types
    .model({
      dataSheet: DataSheet,
      orderIndex: types.number,
      quantity: 1,
      images: types.array(types.reference(Image)),
      previewRequested: false,
    })
    .volatile<{ editor?: Editor; raster?: File }>((self) => ({
      editor: undefined,
      raster: undefined,
    }))
    .views((self) => ({
      get svgFeatures() {
        return !!self.editor
          ? self.editor.svgFeatures
          : {
              userImagesCount: 0,
              userBackgroundColorEditable: false,
            }
      },
    }))
    .actions((self) => ({
      setEditor(editor: Editor) {
        self.editor = editor
      },
      setRaster(raster: File) {
        self.raster = raster
      },
      setPreviewRequested() {
        self.previewRequested = true
      },
      setDataSheet(dataSheet: IDataSheet) {
        self.dataSheet = cast(dataSheet)
      },
      changeQuantity(qty: number) {
        self.quantity = Math.max(self.quantity + qty, 1)
      },
      setImages: flow(function* (images: IImage[]) {
        self.images = cast(images.map((img) => img.id))
        if (images.length === 0 || !images[0].file) return // No image to replace in editor
        yield when(() => !!self.editor) // Editor may come later, TODO: find better place to distribute images
        console.log('set images', images)
        self.editor?.replace(images[0].file) // Single image support in editor for now
      }),
      updateImage(file: File) {
        console.log('update image', file)
        self.editor?.replace(file)

        // Naïve: assume only one image per file
        const img = self.images[0]
        if (!!img) {
          img.setFile(file)
          return
        }
        // New image needs to be added
        const { userConfiguration, productConfiguration } = getRootStore(self)
        const { userImageFileType } = productConfiguration.dataSheets[0]
        const newImg = userConfiguration.addImage({
          name: file.name,
          type: file.type,
          fileType: userImageFileType,
          file,
        })
        self.images.push(newImg.id)
      },
    }))
    .actions((self) => ({
      initEditor: flow(function* (replace: boolean = false) {
        if (self.editor && !replace) {
          console.warn('Editor already initialized')
          return
        }
        const { productConfiguration } = getRoot(self)
        const { photosiEditor } = yield PhotosiEditor
        const editor = yield photosiEditor.addEditor({
          selfStart: true,
          stageId: (self as unknown as IFile).id, // Fuck MST types composition
          svg: {
            preview: self.dataSheet.svgPreviewUrl,
            production: self.dataSheet.svgProductionUrl,
            selfRotate: !!productConfiguration?.canRotateSvg,
          },
          // OLD output key (deprecated)
          raster: {
            width: self.dataSheet.outputWidth,
            height: self.dataSheet.outputHeight,
          },
          // NEW output key
          outputSize: {
            w: self.dataSheet.outputWidth,
            h: self.dataSheet.outputHeight,
          },
        })
        self.setEditor(editor)
      }),
      generatePreview: flow(function* () {
        if (self.previewRequested) return
        self.setPreviewRequested()

        yield when(() => !!self.editor)

        self.editor?.start()
      }),
    }))
    .actions((self) => {
      const fileSelf = self as unknown as IFile // Fuck MST types composition
      const fileUpload: Function = (self as unknown as IFile).upload

      return {
        afterAttach: flow(function* () {
          yield self.initEditor()
        }),
        refreshEditor: flow(function* () {
          yield self.initEditor(true)
          const image = self.images[0]
          if (image && image.file) self.editor?.replace(image.file)
        }),
        getFile: flow(function* () {
          if (!self.editor) {
            throw new Error('Cannot get file: editor is not ready')
          }
          const img = self.images[0] // Single image support for now
          if (!!img) {
            // Await image only if present
            yield when(() => img.isError || !!img.configurationFile?.url)
            const imageUrl = img.configurationFile?.url
            if (!imageUrl) {
              throw new Error('Cannot get file: image is not ready')
            }
            // Then set upload url into svg
            self.editor.presignedImageURL(imageUrl)
            const file = yield self.editor?.rasterize()
            self.setRaster(file)
            return file
          } else {
            // Dirty workaround: editor is not supporting svg without images for now
            // avoid calling it and produce file manually instead
            const svg = yield fetch(self.dataSheet.svgProductionUrl)
            const arrayBuffer = yield svg.arrayBuffer()
            const file = new File([arrayBuffer], 'production.svg')
            self.setRaster(file)
            return file
          }
        }),
        upload: flow(function* () {
          yield fileUpload({
            configurationFileType: self.dataSheet.svgFileType,
            name: fileSelf.id + '.svg',
            quantity: self.quantity,
            orderIndex: self.orderIndex,
          })
          console.log('CWC2021-1454', `SVG ${self.orderIndex}`, fileSelf.configurationFile?.url)
        }),
      }
    })
)

export interface ISheet extends Instance<typeof Sheet> {}
export interface ISheetIn extends SnapshotIn<typeof Sheet> {}
export interface ISheetOut extends SnapshotOut<typeof Sheet> {}

import tw from 'twin.macro'

export const Header = tw.div`
  flex-none
  w-full
  items-center
  p-3
  border-b
  bg-white
`

export const CompositeHeader = tw(Header)`
  grid grid-cols-title
`

import { useDrag, useDrop } from 'react-dnd'

export const useDraggable = <ItemType>(item: ItemType, type = 'BOX') => {
  const [features, ref] = useDrag(
    () => ({
      type,
      item,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0 : 1,
      }),
    }),
    []
  )

  return {
    ref,
    ...features,
  }
}

export const useDroppable = (onDrop, accept = ['BOX']) => {
  const [features, ref] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  return {
    ref,
    ...features,
  }
}

import React from 'react'
import 'twin.macro'

// import SwingData from '../assets/animations/Swing.json'
import LoaderData from '../assets/animations/Loader.json'
import ImageLoaderData from '../assets/animations/ImageLoader.json'
import { Animation } from './Animation'

export const Loader: React.FC = () => {
  return <Animation data={LoaderData} />
}

export const ImageLoader: React.FC = () => {
  return <Animation tw="flex-none w-4" data={ImageLoaderData} />
}

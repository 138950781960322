import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export const ConfigurationFile = types.model({
  id: types.string,
  configurationId: types.string,
  orderIndex: types.maybe(types.number),
  quantity: types.maybe(types.number),
  type: types.string, // use Enum?
  localFileUri: types.maybeNull(types.string),
  uploadUrl: types.string,
  uploaded: types.boolean,
  filename: types.maybeNull(types.string),
  url: types.string,
})

export default ConfigurationFile

export interface IConfigurationFile extends Instance<typeof ConfigurationFile> {}
export interface IConfigurationFileIn extends SnapshotIn<typeof ConfigurationFile> {}
export interface IConfigurationFileOut extends SnapshotOut<typeof ConfigurationFile> {}

import React from 'react'
import { observer } from 'mobx-react-lite'
import 'twin.macro'

import { useMst } from '../models/RootStore'
import PageTypeEnum from '../models/PageType'
import Debug from '../components/Debug'
import { UploadDebugger } from '../components/UploadDebugger'
import ProductTypeSelector from './setup/ProductTypeSelector'
import Configurator from './configuration/Configurator'
import { AddToCart } from './cart/AddToCart'
import { useBeforeUnload } from '../hooks/useBeforeUnload'
import { useUnload } from '../hooks/useUnload'
import { useAmplitude } from '../hooks/useAmplitude'

const PageSelector: React.FC = () => {
  const { currentPage } = useMst()

  useAmplitude()
  useBeforeUnload()
  useUnload()

  return (
    <>
      {currentPage === PageTypeEnum.SETUP ? (
        <ProductTypeSelector />
      ) : currentPage === PageTypeEnum.CONFIGURE ? (
        <Configurator />
      ) : currentPage === PageTypeEnum.SAVE ? (
        <AddToCart />
      ) : null}
    </>
  )
}

export default observer(PageSelector)

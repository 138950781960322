import React, { useState, useEffect, useRef } from 'react'
import tw from 'twin.macro'
import LazyLoad from 'react-lazyload'
import { Waypoint } from 'react-waypoint'

import { ISheet } from '../models/Sheet'
import { IButton } from '../models/Button'
import ButtonTypeEnum from '../models/ButtonType'
import PlusIcon from '../assets/img/icons/Plus.svg'
import MinusIcon from '../assets/img/icons/Minus.svg'
import { useTranslation } from 'react-i18next'
import { PersonalizeActions, PersonalizeButton, CustomPersonalizeButton } from './PersonalizeButton'

type Props = {
  sheet: ISheet
  buttons: IButton[]
  lookups: { [key: string]: string }
  quantity: number
  lazyLoadOffset: number
  verbose: boolean
  onLookupClick: (button: IButton) => void
  onSwapClick: () => void
  onQuantityClick?: (diff: number) => void
  onRemoveClick?: () => void
}

const SvgPreview: React.FC<Props> = ({
  sheet,
  buttons,
  lookups,
  quantity,
  lazyLoadOffset,
  verbose,
  onLookupClick,
  onSwapClick,
  onQuantityClick,
  onRemoveClick,
}) => {
  const [EditorComponent, setEditorComponent] = useState<JSX.Element | null>()
  const input = useRef<HTMLInputElement>(null)
  const { t } = useTranslation('svgEditor')

  const enabledButtons = buttons.filter((b) => {
    switch (b.type) {
      case ButtonTypeEnum.EDIT:
      case ButtonTypeEnum.REPLACE:
      case ButtonTypeEnum.MOVE_PHOTO_CALENDAR:
        return sheet.svgFeatures.userImagesCount > 0
      case ButtonTypeEnum.CHANGE_COLOR:
        return sheet.svgFeatures.userBackgroundColorEditable
    }
    return true
  })

  const updateEditorComponent = async () => {
    const component = await sheet.editor?.element()
    component ? setEditorComponent(component) : console.warn('Editor component is undefined', sheet)
  }
  useEffect(() => {
    if (!sheet.editor) return
    updateEditorComponent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheet.editor])

  const handleButtonClick = (button: IButton) => {
    switch (button.type) {
      case ButtonTypeEnum.CHANGE_COLOR:
        sheet.editor?.chooseBgColor()
        break
      case ButtonTypeEnum.EDIT:
        sheet.editor?.adjust()
        break
      case ButtonTypeEnum.REPLACE:
        input.current?.click()
        break
      case ButtonTypeEnum.LOOKUP:
        onLookupClick(button)
        break
      case ButtonTypeEnum.MOVE_PHOTO_CALENDAR:
        onSwapClick()
        break
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const file = event.target.files[0]
    sheet.updateImage(file)
  }

  return (
    <div tw="mb-2">
      {/*
      <Waypoint onEnter={() => sheet.generatePreview()} />
      <LazyLoad height={lazyLoadOffset} offset={lazyLoadOffset} throttle={100} unmountIfInvisible once overflow>
        <div tw="relative w-full h-0" css={['padding-bottom: 100%']}>
          <div tw="absolute inset-2 flex">{EditorComponent}</div>
        </div>
      </LazyLoad>*/}
      <div tw="relative w-full h-0" css={['padding-bottom: 100%']}>
        <div tw="absolute inset-2 flex">{EditorComponent}</div>
      </div>
      <PersonalizeActions>
        {enabledButtons.map((b) => (
          <PersonalizeButton
            key={b.code}
            type={b.type}
            code={b.code}
            label={b.title}
            description={(b.type === ButtonTypeEnum.LOOKUP && lookups[b.code]) || undefined}
            verbose={verbose}
            onClick={() => handleButtonClick(b)}
          />
        ))}
        {onQuantityClick && (
          <CustomPersonalizeButton label={t('quantity')} verbose={verbose}>
            <div tw="flex">
              <img
                src={MinusIcon}
                alt="-1"
                css={[quantity <= 1 && tw`opacity-25`]}
                onClick={() => onQuantityClick(-1)}
              />
              <span css={[verbose ? tw`px-4` : tw`px-1`]}>{quantity}</span>
              <img src={PlusIcon} alt="+1" onClick={() => onQuantityClick(+1)} />
            </div>
          </CustomPersonalizeButton>
        )}
        {onRemoveClick && (
          <PersonalizeButton
            type={ButtonTypeEnum.DELETE}
            label={t('delete')}
            verbose={verbose}
            onClick={onRemoveClick}
          />
        )}
      </PersonalizeActions>
      <input type="file" accept="image/jpeg" tw="hidden" ref={input} onChange={handleFileChange} />
    </div>
  )
}

export default SvgPreview

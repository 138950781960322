import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { VERBOSE, WEB_EDITOR_BASE_URL } from './consts'

window.React = React
window.ReactDOM = ReactDOM

const editorConfig = {
  verbose: VERBOSE,
  autoCreatePreview: false,
  previewStage: {
    rasterButton: false,
    quantityImage: false,
    replaceImage: false,
    adjustImage: false,
    backgroundColor: false,
    textInput: false,
    textColor: false,
    removeImage: false,
  },
  flowConfig: {
    origin: {
      compress: false,
      resize: false,
    },
  },
  publicPath: WEB_EDITOR_BASE_URL,
  overrideDeviceType: undefined,
  resizeThumbnail: undefined,
  offscreenCanvas: undefined,
  language: undefined,
  imagesPile: undefined,
  cacheStorage: undefined,
  i18nConfig: undefined,
  wasm: undefined,
  rejectSVGProductionWithoutPresignedURL: true,
  sizeCondition: {
    limit: {
      default: undefined,
      weakDevice: undefined,
      network4g: undefined,
      network3g: undefined,
      network2g: undefined,
    },
  },
  showFilter: false,
  thumbnailSize: {
    width: 1600,
    height: 1600,
  },
}

export const PhotosiEditor = new Promise<any>((resolve) => {
  const scriptEditor = document.createElement('script')
  scriptEditor.src = `${WEB_EDITOR_BASE_URL}/umd/package/main.js`
  scriptEditor.type = 'text/javascript'
  document.body.append(scriptEditor)
  scriptEditor.onload = async () => {
    const PhotosiEditorSDK = window.PhotosiEditorSDK
    resolve({
      PhotosiEditorSDK,
      photosiEditor: await PhotosiEditorSDK.initialize({
        ...editorConfig,
      }),
    })
  }
})

import React from 'react'
import tw from 'twin.macro'
import ButtonTypeEnum from '../models/ButtonType'
import DeleteIcon from '../assets/img/icons/Delete.svg'
import ReplaceIcon from '../assets/img/icons/Replace.svg'
import SquareIcon from '../assets/img/icons/Square.svg'
import EditIcon from '../assets/img/icons/Edit.svg'
import SwapIcon from '../assets/img/icons/Swap.svg'
import ChangeColorIcon from '../assets/img/icons/ChangeColor.svg'
import FormatLookupIcon from '../assets/img/icons/FormatLookup.png'
import FrameColorLookupIcon from '../assets/img/icons/FrameColorLookup.png'
import PassepartoutLookupIcon from '../assets/img/icons/PassepartoutLookup.png'

const Button = tw.div`
  flex flex-col flex-1 items-center
`
const Label = tw.div`
  w-full
  mt-1
  text-center text-xs
  whitespace-pre-line
`
const Description = tw.div`
  w-full
  text-center text-xs
  text-gray-400
`

export const PersonalizeActions = tw.div`
  flex flex-wrap justify-around items-start
  m-2
  space-x-2
`

const switchIcon = (type: string, code?: string) => {
  switch (type) {
    case ButtonTypeEnum.EDIT:
      return EditIcon
    case ButtonTypeEnum.REPLACE:
      return ReplaceIcon
    case ButtonTypeEnum.CHANGE_COLOR:
      return ChangeColorIcon
    case ButtonTypeEnum.DELETE:
      return DeleteIcon
    case ButtonTypeEnum.MOVE_PHOTO_CALENDAR:
      return SwapIcon
    case ButtonTypeEnum.LOOKUP:
      switch (code) {
        case 'format':
          return FormatLookupIcon
        case 'frameColor':
          return FrameColorLookupIcon
        case 'passepartout':
          return PassepartoutLookupIcon
      }
  }
  return SquareIcon
}

type Props = {
  type: string
  code?: string
  label?: string
  description?: string
  verbose?: boolean
  onClick: () => void
}

export const PersonalizeButton: React.FC<Props> = ({ children, code, type, label, description, verbose, onClick }) => {
  const src = switchIcon(type, code)
  return (
    <Button onClick={onClick}>
      {children ? children : <img tw="w-6 h-6" src={src} alt={label} />}
      {verbose && label && <Label>{label.replace(' ', '\n')}</Label>}
      {verbose && description && <Description>{description}</Description>}
    </Button>
  )
}

type CustomProps = {
  label?: string
  verbose?: boolean
}
export const CustomPersonalizeButton: React.FC<CustomProps> = ({ label, verbose, children }) => {
  return (
    <Button>
      {children}
      {verbose && label && <Label>{label.replace(' ', '\n')}</Label>}
    </Button>
  )
}

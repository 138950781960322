import { useState, useEffect, useCallback } from 'react'
import { useMst } from '../models/RootStore'
import { IStepIn } from '../models/Step'
import { getDynamicConfiguration } from '../api/dynamicConfigurations'
import { handleHttpError } from '../utils'

const useDynamicConfiguration = (configurationId: string) => {
  const [loading, setLoading] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [isError, setIsError] = useState(false)
  const {
    setDynamicConfiguration,
    session: { selectedLanguage },
  } = useMst()

  const _getDynamicConfiguration = useCallback(async () => {
    if (fetching) return // avoid multiple calls
    setFetching(true)
    setLoading(true)

    if (!configurationId) {
      setLoading(false)
      setFetching(false)
      return
    }
    setIsError(false)
    const dynamicConfiguration = await getDynamicConfiguration(configurationId, selectedLanguage).catch(handleHttpError)
    if (!dynamicConfiguration) {
      setLoading(false)
      setFetching(false)
      setIsError(true)
      return
    }
    const steps: IStepIn[] = dynamicConfiguration.data

    setDynamicConfiguration({
      id: configurationId,
      steps,
      productConfigurationsUrl: dynamicConfiguration.productConfigurationsUrl,
      userConfigurationsUrl: dynamicConfiguration.userConfigurationsUrl,
      configurationFilesUrl: dynamicConfiguration.configurationFilesUrl,
    })
    setLoading(false)
  }, [configurationId, setDynamicConfiguration])

  useEffect(() => {
    _getDynamicConfiguration()
  }, [_getDynamicConfiguration])

  return { loading, isError, retry: _getDynamicConfiguration }
}

export default useDynamicConfiguration

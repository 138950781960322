import tw from 'twin.macro'

export default tw.input`
  w-full
  py-1
  mb-4
  border-b border-gray-200
  focus:(text-teal-400 border-teal-400)
  outline-none
  placeholder-gray-200
`

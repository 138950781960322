import React, { useState } from 'react'
import 'twin.macro'

import { resetPassword } from '../../api/auth'
import Form from '../../components/forms/Form'
import FormInput from '../../components/forms/FormInput'
import FormLabel from '../../components/forms/FormLabel'
import { PrimaryButton } from '../../components/Button'
import { ErrorMessage } from '../../components/Error'
import { SubTitle, Title } from '../../components/Title'
import { CenterModal } from '../../components/Modal'
import { VerticalContents } from '../../components/VerticalContents'
import Pencil from '../../assets/img/Pencil.svg'
import { handleHttpError } from '../../utils'
import { useTranslation } from 'react-i18next'
import { Close } from '../../components/Close'
import { useForm } from 'react-hook-form'
import { FormError } from '../../components/forms/FormError'
import { EMAIL_REGEX } from '../../consts'
import { useMst } from '../../models/RootStore'

type Props = {
  reset: () => void
}

export const ResetPassword: React.FC<Props> = ({ reset }) => {
  const { t } = useTranslation('resetPassword')
  const {
    session: { selectedLanguage },
  } = useMst()
  const [loading, setLoading] = useState(false)
  const {
    register: registerField,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const email = registerField('email', { required: true, pattern: EMAIL_REGEX })
  const [error, setError] = useState('')
  const [completed, setCompleted] = useState(false)

  const formFilled = !!email

  const onSubmit = (data) => {
    setCompleted(false)
    setLoading(true)
    setError('')
    setTimeout(async () => {
      await resetPassword(data.email, selectedLanguage).catch((error) => {
        setError(t('resetPasswordError'))
        handleHttpError(error)
      })
      setCompleted(true)
      setLoading(false)
    }, 1000)
  }

  if (completed && !error) {
    return (
      <CenterModal dismiss={reset}>
        <VerticalContents>
          <img src={Pencil} alt="Pencil" />
          <Title tw="text-2xl">{t('resetPasswordRequest')}</Title>
          <p tw="text-center">{t('resetPasswordInstructions')}</p>
        </VerticalContents>
      </CenterModal>
    )
  }

  return (
    <div>
      <div tw="grid grid-cols-title mb-4">
        <Close tw="place-self-start" onClick={reset} />
        <SubTitle>{t('resetPassword')}</SubTitle>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        {error && <FormError>{error}</FormError>}
        <FormLabel>{t('email')}</FormLabel>
        <FormInput type="email" {...email} placeholder={t('emailPlaceholder')} disabled={loading} />
        {errors.email && <FormError>{t('emailError')}</FormError>}
        <PrimaryButton type="submit" tw="w-full mt-16" disabled={loading || !formFilled}>
          {loading ? t('loading') : t('resetPassword')}
        </PrimaryButton>
      </Form>
    </div>
  )
}
